import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import axios from 'axios';
import {
    Container, Typography, TextField, Button, Link, CircularProgress, FormControl,
    InputLabel, MenuItem,
Select } from '@mui/material';

function CreateForgotPasswordTicketForm() {
    const [userTel, setUserTel] = useState('');
    const [userName, setUserName] = useState('');
    const [ticketContent, setTicketContent] = useState('Forgot Password');

    const navigate = useNavigate(); // useNavigate hook

    const [isSubmitting, setIsSubmitting] = useState(false);
   

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validate required fields
        if (!userName || !ticketContent || !userTel) {
            toast.error('Please fill in all required fields', {
                position: 'top-center',
                autoClose: 5000, // 5 seconds
            });
            return;
        }

        try {
            const ticketData = {
                userTel,
                userName,
                ticketContent,
            };

            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/forgotPasswordTicket`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json', // Set content type to JSON
                },
                body: JSON.stringify(ticketData), // JSON.stringify the body
            });

            if (response.ok) {
                // Handle success
                toast.success('Ticket Successfully submitted', {
                    position: 'top-center',
                    autoClose: 5000, // 5 seconds
                });
                // Clear form fields if needed
                // setUserName('');
                // setTicketContent('');
                // setUserTel('');
            } else {
                toast.error('You have already Submitted Forgot Password Ticket, please wait...', {
                    position: 'top-center',
                    autoClose: 5000, // 5 seconds
                });
            }
        } catch (error) {
            toast.error('You have already Submitted Forgot Password Ticket, please wait...', {
                position: 'top-center',
                autoClose: 5000, // 5 seconds
            });
            console.error('Ticket failed:', error);
        }
    };

    const containerStyle = {
        marginTop: '2rem',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    };

    const formStyle = {
        width: '100%',
        marginTop: '2rem',
    };

    const buttonStyle = {
        marginTop: '2rem',
    };

    const goToRegistrationPage = () => {
        navigate('/register')
    }

    const handleTicketContentChange = (e) => {
        setUserName(e.target.value);
    };

    return (
        <Container component="main" maxWidth="xs" style={containerStyle}>
            <Typography variant="h5"> Forgot Password  </Typography>
        

            <form onSubmit={handleSubmit} style={formStyle}>
                <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    label="Your Name"
                    type="userName"
                    value={userName}
                    onChange={(e) => setUserName(e.target.value)}
                />
 
                
             

             


                <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    label="Your Tel"
                    type="userTel"
                    value={userTel}
                    onChange={(e) => setUserTel(e.target.value)}
                />


                <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    label="Raison"
                    type="userTel"
                    value={ticketContent}
                    onChange={(e) => setTicketContent(e.target.value)}
                />

            
                

                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    // style={buttonStyle}
                    style={{ marginTop: '20px', position: 'relative' }} // Position relative for CircularProgress
                    disabled={isSubmitting} // Disable button while submitting
                >
                    {isSubmitting ? (
                        <CircularProgress size={24} color="inherit" /> // Show CircularProgress while submitting
                    ) : (
                        'Forgot Password Ticket'
                    )}
                </Button>
            </form>
            <ToastContainer position="top-center" autoClose={5000} />

        </Container>
    );
}

export default CreateForgotPasswordTicketForm;
