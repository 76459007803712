import React, { useEffect, useRef } from 'react';
import * as echarts from 'echarts';

function BarChart() {
    const chartRef = useRef(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch data from the API
                const response = await fetch(`${process.env.REACT_APP_BASE_URL}/goods-tracking/displayAll`, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    },
                });
                const data = await response.json();

                // Process the data to calculate the total goodsTotalWeight for each unique client name
                const clientsData = {};
                data.forEach((item) => {
                    const clientName = item.clientName;
                    const goodsTotalWeight = item.goodsTotalWeight;
                    if (clientName) {
                        if (clientsData[clientName]) {
                            clientsData[clientName] += goodsTotalWeight;
                        } else {
                            clientsData[clientName] = goodsTotalWeight;
                        }
                    }
                });

                // Extract all unique client names
                const allClientNames = Object.keys(clientsData);

                // Prepare data for the bar chart
                const barData = allClientNames.map((clientName) => ({
                    value: clientsData[clientName],
                    name: clientName,
                }));

                // Initialize ECharts and create the chart
                const myChart = echarts.init(chartRef.current);
                const option = {
                    title: {
                        text: '',
                        left: 'center',
                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow', // Show shadow for better visualization
                        },
                    },
                    xAxis: {
                        type: 'category',
                        data: allClientNames,
                        axisLabel: {
                            rotate: 45, // Rotate the client names by 45 degrees
                        },
                    },
                    yAxis: {
                        type: 'value',
                    },
                    series: [
                        {
                            name: 'Total Goods Weight',
                            type: 'bar', // Change type to 'bar' for a bar chart
                            data: barData,
                        },
                    ],
                };

                // Set the chart options and render
                myChart.setOption(option);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    return <div ref={ chartRef } style={ { width: '100%', height: '400px' } }></div>;
}

export default BarChart;
