import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    CircularProgress, Grid,
    FormControl,
    InputLabel,
    Select, TextField,
    MenuItem,
    Button,
} from '@mui/material';

import { ToastContainer, toast } from 'react-toastify';

function UserTicketsTable() {
    const [tickets, setTickets] = useState([]);
    const [adminComment, setAdminComment] = useState('');
    const [userComment, setUserComment] = useState(''); // State for user comments
    const [loading, setLoading] = useState(true);
    const [filterStatus, setFilterStatus] = useState(''); // State for ticket status filter
    const [filterType, setFilterType] = useState(''); // State for type of ticket filter
    const [filterUserTel, setFilterUserTel] = useState(''); // State for User Tel filter
    const [editMode, setEditMode] = useState({}); // State for edit mode of each row
    const authToken = localStorage.getItem('token');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/ticket/listForAdmin`, {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                    },
                });
                setTickets(response.data.tickets);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching user tickets:', error);
                setLoading(false);
            }
        };

        fetchData();
    }, [authToken]);

    const getStatusColor = (status) => {
        switch (status) {
            case 'pending':
                return '#FFCC80'; // Light Orange
            case 'processing':
                return '#FFFF99'; // Light Yellow
            case 'completed':
                return '#B2FF66'; // Light Green
            case 'cancelled':
                return '#FF9999'; // Light Red
            default:
                return '#E0E0E0'; // Light Gray (default)
        }
    };

    const filteredTickets = tickets
        .filter(
            (ticket) =>
                (!filterStatus || ticket.status === filterStatus) &&
                (!filterType || ticket.typeOfTicket === filterType) &&
                (!filterUserTel || ticket.userTel.includes(filterUserTel))
        )
        .sort((a, b) => new Date(b.date) - new Date(a.date));

    // Handle entering edit mode for a row
    const handleEditClick = (ticketId, currentAdminComment, currentUserComment) => {
        setEditMode({ ...editMode, [ticketId]: true });
        setAdminComment(currentAdminComment || '');
        setUserComment( currentUserComment || '' );
    };

    // Handle canceling edit mode for a row
    const handleCancelEdit = (ticketId) => {
        setEditMode({ ...editMode, [ticketId]: false });
    };

    // Handle saving the edited ticket status and adminComment
    const handleSaveEdit = async (ticketId, newStatus) => {
        console.log(`ADMIN_COMMENT ${adminComment}`);
        try {
            const response = await axios.put(
                `${process.env.REACT_APP_BASE_URL}/ticket/update/${ticketId}`,
                { status: newStatus, adminComment: adminComment, userComment: userComment[ticketId] },
                {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                    },
                }
            );

            if (response.status === 200) {
                const updatedTickets = [...tickets];
                const ticketIndex = updatedTickets.findIndex((ticket) => ticket._id === ticketId);
                if (ticketIndex !== -1) {
                    updatedTickets[ticketIndex].status = newStatus;
                    updatedTickets[ticketIndex].adminComment = adminComment;
                    updatedTickets[ticketIndex].userComment = userComment;
                    setTickets(updatedTickets);
                }

                toast.success('Update Successfully', {
                    position: 'top-center',
                    autoClose: 5000,
                });

                setEditMode({ ...editMode, [ticketId]: false });
            }
        } catch (error) {
            console.error('Error updating ticket status:', error);
        }
    };

    const handleUserCommentChange = (ticketId, comment) => {
        setUserComment({ ...userComment, [ticketId]: comment });
    };

    const handleDeleteTicket = async (ticketId) => {
        try {
            const response = await axios.delete(
                `${process.env.REACT_APP_BASE_URL}/ticket/delete/${ticketId}`,
                {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                    },
                }
            );

            if (response.status === 200) {
                setTickets(tickets.filter((ticket) => ticket._id !== ticketId));
                toast.success('Ticket deleted successfully', {
                    position: 'top-center',
                    autoClose: 5000,
                });
            }
        } catch (error) {
            console.error('Error deleting ticket:', error);
        }
    };

    const labelStyle = {
        transform: filterStatus !== '' || filterType !== '' ? 'translate(7px, -7px)' : 'translate(7px, -7px)',
        fontSize: filterStatus !== '' || filterType !== '' ? 12 : 12,
        backgroundColor: 'white'
    };

    const formatDateToFrench = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        if (isNaN(date.getTime())) return 'Date invalide';
        return date.toLocaleDateString('fr-FR', {
            day: 'numeric',
            month: 'long',
            year: 'numeric'
        });
    };

    return (
        <div>
            <Typography variant="h6" style={{ fontSize: '2rem', fontWeight: 'bold' }}>
                All Tickets 🎫
            </Typography>
            <div>
                <Grid
                    style={{ marginTop: '10px' }}
                    container spacing={3}
                >
                    <Grid item xs={6} sm={3}>
                        <FormControl style={{ minWidth: 150, marginRight: 20 }}>
                            <InputLabel shrink={filterStatus !== ''} htmlFor="filter-status" style={labelStyle}>
                                Status
                            </InputLabel>
                            <Select
                                value={filterStatus}
                                onChange={(e) => setFilterStatus(e.target.value)}
                                inputProps={{
                                    name: 'filter-status',
                                    id: 'filter-status',
                                }}
                            >
                                <MenuItem value="">All</MenuItem>
                                <MenuItem value="pending">Pending</MenuItem>
                                <MenuItem value="processing">Processing</MenuItem>
                                <MenuItem value="completed">Completed</MenuItem>
                                <MenuItem value="cancelled">Cancelled</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={6} sm={3}>
                        <FormControl style={{ minWidth: 150 }}>
                            <InputLabel shrink={filterType !== ''} htmlFor="filter-type" style={labelStyle}>
                                Type of Ticket
                            </InputLabel>
                            <Select
                                value={filterType}
                                onChange={(e) => setFilterType(e.target.value)}
                                inputProps={{
                                    name: 'filter-type',
                                    id: 'filter-type',
                                }}
                            >
                                <MenuItem value="">All</MenuItem>
                                <MenuItem value="access">Access</MenuItem>
                                <MenuItem value="other">Other</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={6} sm={3}>
                        <TextField
                            label="User Tel"
                            value={filterUserTel}
                            onChange={(e) => setFilterUserTel(e.target.value)}
                        />
                    </Grid>
                </Grid>
            </div>

            <TableContainer style={{ height: '750px', marginTop: '19px' }} component={Paper}>
                <Table>
                    <TableHead
                        style={{
                            backgroundColor: '#78AEF9',
                            position: 'sticky',
                            top: 0,
                            zIndex: 1000,
                            fontWeight: 'bold',
                        }}
                    >
                        <TableRow>
                            <TableCell style={{ fontSize: '0.96rem', fontFamily: 'monospace', fontWeight: 'bold' }}>Name</TableCell>
                            <TableCell style={{ fontSize: '0.96rem', fontFamily: 'monospace', fontWeight: 'bold' }}>Status</TableCell>
                            <TableCell style={{ fontSize: '0.96rem', fontFamily: 'monospace', fontWeight: 'bold' }}>Type Of Ticket</TableCell>
                            <TableCell style={{ fontSize: '0.96rem', fontFamily: 'monospace', fontWeight: 'bold' }}>User Name</TableCell>
                            <TableCell style={{ fontSize: '0.96rem', fontFamily: 'monospace', fontWeight: 'bold' }}>User Tel</TableCell>
                            <TableCell style={{ fontSize: '0.96rem', fontFamily: 'monospace', fontWeight: 'bold' }}>Date</TableCell>
                            <TableCell style={{ fontSize: '0.96rem', fontFamily: 'monospace', fontWeight: 'bold' }}>Admin Comment</TableCell>
                            <TableCell style={{ fontSize: '0.96rem', fontFamily: 'monospace', fontWeight: 'bold' }}>User Comment</TableCell>
                            <TableCell style={{ fontSize: '0.96rem', fontFamily: 'monospace', fontWeight: 'bold' }}>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredTickets.map((ticket) => (
                            <TableRow key={ticket._id} style={{ backgroundColor: getStatusColor(ticket.status) }}>
                                <TableCell>{ticket.name}</TableCell>
                                <TableCell>{ticket.status}</TableCell>
                                <TableCell>{ticket.typeOfTicket}</TableCell>
                                <TableCell>{ticket.userName}</TableCell>
                                <TableCell>{ticket.userTel}</TableCell>
                                <TableCell>{formatDateToFrench(ticket.date)}</TableCell>
                                <TableCell>
                                    {editMode[ticket._id] ? (
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            value={adminComment}
                                            onChange={(e) => setAdminComment(e.target.value)}
                                        />
                                    ) : (
                                        ticket.adminComment
                                    )}
                                </TableCell>
                                <TableCell>
                                    {editMode[ticket._id] ? (
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            value={userComment}
                                            onChange={(e) => setUserComment(e.target.value)}
                                        />
                                    ) : (
                                        ticket.userComment
                                    )}
                                </TableCell>
                                <TableCell>
                                    {editMode[ticket._id] ? (
                                        <>
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                onClick={() => handleSaveEdit(ticket._id, ticket.status)}
                                                style={{ marginRight: '8px' }}
                                            >
                                                Save
                                            </Button>
                                            <Button
                                                variant="outlined"
                                                color="secondary"
                                                onClick={() => handleCancelEdit(ticket._id)}
                                                style={{ marginRight: '8px' }}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                variant="outlined"
                                                color="error"
                                                onClick={() => handleDeleteTicket(ticket._id)}
                                            >
                                                Delete
                                            </Button>
                                        </>
                                    ) : (
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            onClick={() => handleEditClick(ticket._id, ticket.adminComment, ticket.userComment)}
                                        >
                                            Edit
                                        </Button>
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <ToastContainer position="top-center" autoClose={5000} />
            </TableContainer>
        </div>
    );
}

export default UserTicketsTable;
