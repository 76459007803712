import React, { useEffect } from 'react';
import { Link } from 'react-scroll';
// import './css/HomePage.css'; // Import your CSS file for styling



// import TopPageBlock from './blocs/TopPageBlock';
// import GetToKnow from './blocs/GetToKnow';
// import HowOurPlatformWorks from './blocs/HowOurPlatformWorks';
// import UniqueFeature from './blocs/UniqueFeature';
// import HearWhatOtherSaid from './blocs/HearWhatOtherSaid';
// import Footer from './blocs/Footer';


const HomePage = () => {
    useEffect(() => {
        
        // Add event listener to handle scrolling
        window.addEventListener('scroll', handleScroll);
        return () => {
            // Cleanup event listener on component unmount
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleScroll = () => {
       
    };

    return (
        <></>
        // <div>
        //     {/* <TopPageBlock /> */}
        //     {/* <GetToKnow /> */}
        //     {/* <HowOurPlatformWorks /> */}
        //     {/* <UniqueFeature/> */}
        //     {/* <HearWhatOtherSaid/> */}
        //     {/* <Footer/> */}
        //     {/* Use other block components similarly */ }
        // </div>
    );
};

export default HomePage;