import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    CircularProgress,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button
} from '@mui/material';

// import ReactDatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css'; // Import the styles



import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

import ImageModal from '../modals/ImageModal';
import UploadModal from '../modals/UploadModalForSender';

function GoodsTable() {
    const [goodsList, setGoodsList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const initialFilters = {
        status: '',
        startDate: '', // Add startDate and endDate fields
        endDate: '',   // Add startDate and endDate fields
        countryOrigin: '',
        countryDestination: '',
        clientName: '',
        clientTel: '',
        senderName: '',
        senderTel: '',
    };

    const [modalGoodsId, setModalGoodsId] = useState(null); // Store the goodsID for the modal
    const [modalGoodsText, setModalGoodsText] = useState(null); // Store the goodsID for the modal


    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedRowId, setSelectedRowId] = useState(null);
    const [isUploadModalOpen, setIsUploadModalOpen] = useState(false); // State for upload modal


    const handleImageClick = (imageUrl, rowId) => {
        setSelectedImage(imageUrl);
        setSelectedRowId(rowId);
    };

    const handleCloseModal = () => {
        setSelectedImage(null);
        setSelectedRowId(null);
    };


    const [filters, setFilters] = useState(initialFilters);

    const [isEditing, setIsEditing] = useState(false);
    const [editedGoods, setEditedGoods] = useState({});

    const [currentlyEditedRow, setCurrentlyEditedRow] = useState(null);

    const toggleEdit = (goodsId) => {
        setCurrentlyEditedRow(goodsId === currentlyEditedRow ? null : goodsId);
        setEditedGoods({ ...editedGoods, [goodsId]: {} });
    };

    const handleEditChange = (goodsId, field, value) => {
        setEditedGoods((prevEditedGoods) => ({
            ...prevEditedGoods,
            [goodsId]: {
                ...prevEditedGoods[goodsId],
                [field]: value,
            },
        }));
    };

    // Function to cancel the edit and revert to the original values
    const cancelEdit = (rowId) => {
        // Remove the row from the editedGoods state
        const newEditedGoods = { ...editedGoods };
        delete newEditedGoods[rowId];
        setEditedGoods(newEditedGoods);

        // Reset the currentlyEditedRow state
        setCurrentlyEditedRow(null);
    };

    const handleDateChange = (goodsId, field, date) => {
        setEditedGoods((prevEditedGoods) => ({
            ...prevEditedGoods,
            [goodsId]: {
                ...prevEditedGoods[goodsId],
                [field]: date,
            },
        }));
    };

    const testID = (goodsID) => {
        console.log('testID');
        console.log(goodsID);
    };
    const handleUploadButtonClick = (goodsID,targetImageTitle) => {
        setModalGoodsId(goodsID); // Set the goodsID for the modal
        setModalGoodsText(targetImageTitle); //
        setIsUploadModalOpen(true); // Open the upload modal
    };

    const handleUploadModalClose = () => {
        setIsUploadModalOpen(false); // Close the upload modal
    };


    const saveEditedGoods = async (goodsId) => {
        const authToken = localStorage.getItem('token');
        const editedData = editedGoods[goodsId];

        console.log(editedData); // Log the data before the asynchronous update
        console.log(goodsId); // Log the data before the asynchronous update

        try {
            await axios.put(`${process.env.REACT_APP_BASE_URL}/goods-tracking/updateBySender/${goodsId}`, editedData, {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            });

            // Update the local state with the edited values
            setGoodsList((prevGoodsList) =>
                prevGoodsList.map((goods) =>
                    goods._id === goodsId ? { ...goods, ...editedData } : goods
                )
            );

            // Toggle off editing mode
            toggleEdit();
        } catch (error) {
            console.error('Error updating goods:', error);
        }
    };




    // Function to reset filters to their initial state
    const handleResetFilters = () => {
        setFilters(initialFilters);
    };

    useEffect(() => {
        const authToken = localStorage.getItem('token');

        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/goods-tracking/sendergoods/displayAll`, {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                    },
                });

                if (response.status === 200) {
                    // Format the dates before setting the goodsList
                    const formattedGoodsList = response.data.map((goods) => ({
                        ...goods,
                        date: goods.date ? new Date(goods.date).toLocaleDateString() : '',
                        receptionDate: goods.receptionDate ? new Date(goods.receptionDate).toLocaleDateString() : '',
                        deliveryDate: goods.deliveryDate ? new Date(goods.deliveryDate).toLocaleDateString() : '',
                    }));

                    setGoodsList(formattedGoodsList);
                    setIsLoading(false);
                } else {
                    console.error('Error fetching goods:', response.statusText);
                    setIsLoading(false);
                }
            } catch (error) {
                console.error('Error fetching goods:', error);
                setIsLoading(false);
                // window.location.href = '/goodsTableForUser';

            }
        };

        fetchData();

    }, []);


    useEffect(() => {
        const userRole = localStorage.getItem('userRole');
        if (userRole && userRole !== 'sender') {
            window.location.href = '/goodsTableForUser';

        }
    }, []);

    const handleFilterChange = (event) => {
        const { name, value } = event.target;
        setFilters({ ...filters, [name]: value });
    };
    const filteredGoods = goodsList.filter((goods) => {
        const {
            _id,
            status,
            startDate, // Update to use startDate and endDate
            endDate,   // Update to use startDate and endDate
            countryOrigin,
            countryDestination,
            clientName,
            clientTel,
            senderName,
            senderTel,
        } = filters;

        const goodsDate = new Date(goods.date);
        // Check if _id is provided in filters and if it is included in the goods._id as a substring
        const idFilterMatch = !_id || goods._id.toString().includes(_id.toString());

        return (
            idFilterMatch &&
            (status === '' || goods.status === status) &&
            (!startDate || goodsDate >= new Date(startDate)) && // Check for start date
            (!endDate || goodsDate <= new Date(endDate)) &&     // Check for end date
            (countryOrigin === '' || goods.goodsCountryOrigin === countryOrigin) &&
            (countryDestination === '' || goods.goodsCountryDestination === countryDestination) &&
            (clientName === '' || goods.clientName.includes(clientName)) &&
            (clientTel === '' || goods.clientTel.includes(clientTel)) &&
            (senderName === '' || goods.senderName.includes(senderName)) &&
            (senderTel === '' || goods.senderTel.includes(senderTel))
        );
    });


    if (isLoading) {
        return (
            <div style={{ textAlign: 'center' }}>
                <CircularProgress />
            </div>
        );
    }



    const generateExcelData = () => {
        const data = [];
        data.push([
            'Goods ID',
            'Goods Name',
            'Goods Status',
            'Goods Description',
            'Goods Type',
            'Goods Quantity',
            'Country Origin',
            'Country Destination',
            'Total Weight',
            'Total CBM',
            'Shipping Mode',
            'Current Location',
            'Client Name',
            'Client Tel',
            'Client Address',
            'Client Paid Amount',
            'Client Payment Status',
            'Sender Name',
            'Sender Tel',
            'Sender Payment Amount',
            'Sender Payment Status',
            'Admin Comment',
            'Sender Comment',
            'Client',
            'Date',
            'Delivery Date',
            'Reception Date',
        ]);

        filteredGoods.forEach((goods) => {
            data.push([
                goods._id.slice(-6), // Extract the last 6 digits of the ID
                goods.goodsName,
                goods.status,
                goods.goodsDescription,
                goods.goodsType,
                goods.goodsQuantity,
                goods.goodsCountryDestination,
                goods.goodsTotalWeight,
                goods.goodsTotalCBM,
                goods.goodsShippingMode,
                goods.goodsCurrentCountrylocation,
                goods.senderName,
                goods.senderTel,
                goods.senderPaymentAmount,
                goods.senderPayementStatus,
                goods.adminComment,
                goods.senderComment,
                goods.client,
                goods.date,
                goods.deliveryDate,
                goods.receptionDate,
            ]);
        });

        return data;
    };

    const handleDownloadExcel = () => {
        const data = generateExcelData();
        const ws = XLSX.utils.aoa_to_sheet(data);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Expenses');
        XLSX.writeFile(wb, 'expenses.xlsx');
    
    };

    // function handleDownloadExcel() {
    //     const data = generateExcelData();
    //     const ws = XLSX.utils.aoa_to_sheet(data);
    //     const wb = XLSX.utils.book_new();
    //     XLSX.utils.book_append_sheet(wb, ws, 'Expenses');
    //     XLSX.writeFile(wb, 'expenses.xlsx');
    // }

    



    

    return (
        <div style={{ margin: '0 auto', maxWidth: '1200px' }}>
            <div style={{ marginBottom: '16px', display: 'flex', flexWrap: 'wrap', alignItems: 'center', gap: '16px' }}>
                

                <FormControl style={{ minWidth: '150px', flex: '1' }}>
                    {/* <InputLabel>Goods ID</InputLabel> */}
                    <TextField
                        label="Goods ID"
                        name="_id"
                        value={filters._id}
                        onChange={handleFilterChange}
                    />
                </FormControl><FormControl style={{ minWidth: '150px', flex: '1' }}>


                    <InputLabel>Status</InputLabel>
                    <Select
                        name="status"
                        value={filters.status}
                        onChange={handleFilterChange}
                    >
                        <MenuItem value="">All</MenuItem>
                        <MenuItem value="pending">Pending</MenuItem>
                        <MenuItem value="shipping">Shipping</MenuItem>
                        <MenuItem value="packing">Packing</MenuItem>
                    </Select>
                </FormControl>

               


                <FormControl style={{ minWidth: '150px', flex: '1' }}>
                    <InputLabel>Country Origin</InputLabel>
                    <Select
                        name="countryOrigin"
                        value={filters.countryOrigin}
                        onChange={handleFilterChange}
                    >
                        <MenuItem value="">All</MenuItem>
                        <MenuItem value="China">China</MenuItem>
                        <MenuItem value="Guinea">Guinea</MenuItem>
                        {/* Add more country options as needed */}
                    </Select>
                </FormControl>

                <FormControl style={{ minWidth: '150px', flex: '1' }}>
                    <InputLabel>Country Destination</InputLabel>
                    <Select
                        name="countryDestination"
                        value={filters.countryDestination}
                        onChange={handleFilterChange}
                    >
                        <MenuItem value="">All</MenuItem>
                        <MenuItem value="China">China</MenuItem>
                        <MenuItem value="Guinea">Guinea</MenuItem>
                        {/* Add more country options as needed */}
                    </Select>
                </FormControl>

            
 

              

              
            </div>

            <div style={{ marginBottom: '16px', display: 'flex', flexWrap: 'wrap', alignItems: 'center', gap: '16px' }}>

            <FormControl style={{ minWidth: '150px', flex: '1' }}>
                <p>Fitler Start Date</p>
                <TextField
                    name="startDate"
                    type="date"
                    value={filters.startDate}
                    onChange={handleFilterChange}
                />
            </FormControl>

            <FormControl style={{ minWidth: '150px', flex: '1' }}>
                <p> Filter End Date</p>
                <TextField
                    name="endDate"
                    type="date"
                    value={filters.endDate}
                    onChange={handleFilterChange}
                />
            </FormControl>

            </div>
          

            <div style={{ marginBottom: '16px', display: 'flex', flexWrap: 'wrap', alignItems: 'center', gap: '16px' }}>
            <Button
                variant="contained"
                style={{ backgroundColor: 'darkred', color: 'white', flex: '1', minWidth: '150px', marginTop: '16px' }}
                onClick={handleResetFilters}
            >
                Reset Filters
            </Button>

            <Button
                variant="contained"
                onClick={handleDownloadExcel}
                    style={{ backgroundColor: 'primary', color: 'white', flex: '1', minWidth: '150px', marginTop: '16px' }}

            >
                Download 
            </Button>
           
            </div>



           
        <di> <h3>Total {filteredGoods.length} </h3> </di>

            {/* Upload modal */}
            {isUploadModalOpen && (
                <UploadModal goodsID={modalGoodsId} modalGoodsText={modalGoodsText} onClose={handleUploadModalClose} />
            )}
            {selectedImage && (
                <ImageModal imageUrl={selectedImage} onClose={handleCloseModal} />
            )}
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                            <TableCell>Edit</TableCell>
                            <TableCell>Goods ID</TableCell>
                            <TableCell>Goods Images</TableCell>
                        <TableCell>Goods Name</TableCell>
                        <TableCell>Goods Status</TableCell>
                            <TableCell>Sender Receipt</TableCell>
                            <TableCell>Sender Paid ?</TableCell>

                        <TableCell>Goods Description</TableCell>
                        <TableCell>Goods Type</TableCell>
                        <TableCell>Goods Quantity</TableCell>
                        <TableCell>Country Destination</TableCell>
                        <TableCell>Total Weight</TableCell>
                        <TableCell>Total CBM</TableCell>
                        <TableCell>Shipping Mode</TableCell>
                        <TableCell>Current Location</TableCell>
                    
                        <TableCell>Sender Name</TableCell>
                        <TableCell>Sender Tel</TableCell>
                        <TableCell>Sender Payment Amount</TableCell>
                        <TableCell>Sender Comment</TableCell>
                        <TableCell>Date</TableCell>
                        <TableCell>Delivery Date</TableCell>
                        <TableCell>Reception Date</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                       
                      

                        {filteredGoods.map((goods) => (
                          
                            <TableRow key={goods._id}>
                                <TableCell>
                                    {currentlyEditedRow === goods._id ? (

                                        <>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() => saveEditedGoods(goods._id)}
                                            >
                                                Save
                                            </Button>


                                            <Button
                                                variant="contained"
                                                onClick={() => cancelEdit(goods._id)}
                                                style={{ backgroundColor: 'darkred', color: 'white', marginTop: '5px' }}
                                            >
                                                Cancel
                                            </Button>

                                        </>



                                    ) : (
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => toggleEdit(goods._id)}
                                        >
                                            Edit
                                        </Button>
                                    )}
                                </TableCell>
                              
                                <TableCell>{goods._id.slice(-6)}</TableCell>
                                {/* <TableCell>{goods.goodsImagesLinks}</TableCell> */}
                                <TableCell>
                                    <div style={{ whiteSpace: 'nowrap' }}>
                                        
                                     
                                        {currentlyEditedRow === goods._id ? (
                                       
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => handleUploadButtonClick(goods._id, 'goodsImagesLinks')}
                                                style={{
                                                    borderRadius: '50%',  // Make the button round
                                                    width: '30px',        // Set the button width
                                                    height: '30px',       // Set the button height
                                                    padding: '0',         // Remove padding
                                                    minWidth: 'unset',    // Remove minWidth
                                                }}
                                                >
                                                    + 
                                                </Button>


                                        ) : (
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => handleUploadButtonClick(goods._id, 'goodsImagesLinks')}
                                                    style={{
                                                        borderRadius: '50%',  // Make the button round
                                                        width: '30px',        // Set the button width
                                                        height: '30px',       // Set the button height
                                                        padding: '0',         // Remove padding
                                                        minWidth: 'unset',    // Remove minWidth
                                                    }}
                                                >
                                                    + 
                                                </Button>

                                        )}
                                            {goods.goodsImagesLinks.map((imageLink, index) => (

                                                <img
                                                    key={index}
                                                    src={imageLink}
                                                    alt={`Image ${index}`}
                                                    style={{
                                                        display: 'inline-block',
                                                        maxWidth: '43px',
                                                        maxHeight: '43px',
                                                        marginRight: '5px',
                                                        borderRadius: '50px', // Set your desired border radius
                                                        marginTop: '0',      // Remove marginTop
                                                        marginBottom: '0'    // Remove marginBottom
                                                    }}
                                                    onClick={() => handleImageClick(imageLink)}
                                                />

                                            ))}
                                    </div>
                                 
                                </TableCell>
                              



                                <TableCell 
                                
                                style={{
                                whiteSpace: 'nowrap', // Prevent content from wrapping
                                overflow: 'hidden',   // Hide overflowing content
                                textOverflow: 'ellipsis', // Show ellipsis (...) for overflowing content
                                minWidth: '100px',   // Set a minimum width for the cell
                                width: 'auto'}}
                                >

                                    {currentlyEditedRow === goods._id ? (
                                        <TextField
                                            value={editedGoods[goods._id]?.goodsName || goods.goodsName}
                                            onChange={(e) =>
                                                handleEditChange(goods._id, 'goodsName', e.target.value)
                                            }
                                        />
                                    ) : (
                                        goods.goodsName
                                    )}
                                </TableCell>


                                <TableCell 
                                    style={{
                                        whiteSpace: 'nowrap', // Prevent content from wrapping
                                        overflow: 'hidden',   // Hide overflowing content
                                        textOverflow: 'ellipsis', // Show ellipsis (...) for overflowing content
                                        minWidth: '100px',   // Set a minimum width for the cell
                                        width: 'auto'
                                    }}>

                                    {currentlyEditedRow === goods._id ? (
                                        <FormControl>
                                            <InputLabel>Status</InputLabel>
                                            <Select
                                                value={editedGoods[goods._id]?.status || goods.status}
                                                onChange={(e) =>
                                                    handleEditChange(goods._id, 'status', e.target.value)
                                                }
                                            >
                                                <MenuItem value="pending">Pending</MenuItem>
                                                <MenuItem value="shipping">Shipping</MenuItem>
                                                <MenuItem value="packing">Packing</MenuItem>
                                            </Select>
                                        </FormControl>
                                    ) : (
                                        goods.status
                                    )}
                                </TableCell>

                                <TableCell>
                                    <div style={{ whiteSpace: 'nowrap' }}>


                                        {currentlyEditedRow === goods._id ? (

                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() => handleUploadButtonClick(goods._id, 'goodsImagesLinks')}
                                                style={{
                                                    borderRadius: '50%',  // Make the button round
                                                    width: '30px',        // Set the button width
                                                    height: '30px',       // Set the button height
                                                    padding: '0',         // Remove padding
                                                    minWidth: 'unset',    // Remove minWidth
                                                }}
                                            >
                                                +
                                            </Button>


                                        ) : (
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() => handleUploadButtonClick(goods._id, 'senderReceptImagesLinks')}
                                                style={{
                                                    borderRadius: '50%',  // Make the button round
                                                    width: '30px',        // Set the button width
                                                    height: '30px',       // Set the button height
                                                    padding: '0',         // Remove padding
                                                    minWidth: 'unset',    // Remove minWidth
                                                }}
                                            >
                                                +
                                            </Button>

                                        )}
                                        {goods.senderReceptImagesLinks.map((imageLink, index) => (

                                            <img
                                                key={index}
                                                src={imageLink}
                                                alt={`Image ${index}`}
                                                style={{
                                                    display: 'inline-block',
                                                    maxWidth: '43px',
                                                    maxHeight: '43px',
                                                    marginRight: '5px',
                                                    borderRadius: '50px', // Set your desired border radius
                                                    marginTop: '0',      // Remove marginTop
                                                    marginBottom: '0'    // Remove marginBottom
                                                }}
                                                onClick={() => handleImageClick(imageLink)}
                                            />

                                        ))}
                                    </div>

                                </TableCell>


                                <TableCell>
                                    {currentlyEditedRow === goods._id ? (
                                       <>
                                            
                                            {goods.senderPayementStatus }
                                       </>

                                    ) : (
                                        goods.senderPayementStatus
                                    )}
                                </TableCell>


                                <TableCell>
                                    {currentlyEditedRow === goods._id ? (
                                        <TextField
                                            value={editedGoods[goods._id]?.goodsDescription ||  goods.goodsDescription }
                                            onChange={(e) =>
                                                handleEditChange(goods._id, 'goodsDescription', e.target.value)
                                            }
                                        />
                                    ) : (
                                            goods.goodsDescription
                                    )}
                                </TableCell>



                                <TableCell
                                    style={{
                                        whiteSpace: 'nowrap', // Prevent content from wrapping
                                        overflow: 'hidden',   // Hide overflowing content
                                        textOverflow: 'ellipsis', // Show ellipsis (...) for overflowing content
                                        minWidth: '100px',   // Set a minimum width for the cell
                                        width: 'auto'
                                    }}
                                >
                                    {currentlyEditedRow === goods._id ? (
                                        <TextField
                                            value={editedGoods[goods._id]?.goodsType || goods.goodsType}
                                            onChange={(e) =>
                                                handleEditChange(goods._id, 'goodsType', e.target.value)
                                            }
                                        />
                                    ) : (
                                            goods.goodsType
                                    )}
                                </TableCell>


                                <TableCell
                                
                                    style={{
                                        whiteSpace: 'nowrap', // Prevent content from wrapping
                                        overflow: 'hidden',   // Hide overflowing content
                                        textOverflow: 'ellipsis', // Show ellipsis (...) for overflowing content
                                        minWidth: '100px',   // Set a minimum width for the cell
                                        width: 'auto'
                                    }}>

                                    {currentlyEditedRow === goods._id ? (
                                        <TextField
                                            value={editedGoods[goods._id]?.goodsQuantity || goods.goodsQuantity}
                                            onChange={(e) =>
                                                handleEditChange(goods._id, 'goodsQuantity', e.target.value)
                                            }
                                        />
                                    ) : (
                                        goods.goodsQuantity
                                    )}
                                </TableCell>


                              


                                <TableCell>
                                    {currentlyEditedRow === goods._id ? (
                                        <FormControl>
                                            <InputLabel>Status</InputLabel>
                                            <Select
                                                value={editedGoods[goods._id]?.goodsCountryDestination || goods.goodsCountryDestination}
                                                onChange={(e) =>
                                                    handleEditChange(goods._id, 'goodsCountryDestination', e.target.value)
                                                }
                                            >
                                                <MenuItem value="China">China</MenuItem>
                                                <MenuItem value="Guinea">Guinea</MenuItem>
                                            </Select>
                                        </FormControl>
                                    ) : (
                                        goods.goodsCountryDestination
                                    )}
                                </TableCell>


                                <TableCell 
                                
                                    style={{
                                        whiteSpace: 'nowrap', // Prevent content from wrapping
                                        overflow: 'hidden',   // Hide overflowing content
                                        textOverflow: 'ellipsis', // Show ellipsis (...) for overflowing content
                                        minWidth: '100px',   // Set a minimum width for the cell
                                        width: 'auto'
                                    }}
                                    
                                    >
                                    {currentlyEditedRow === goods._id ? (
                                        <TextField
                                            value={editedGoods[goods._id]?.goodsTotalWeight || goods.goodsTotalWeight}
                                            onChange={(e) =>
                                                handleEditChange(goods._id, 'goodsTotalWeight', e.target.value)
                                            }
                                        />
                                    ) : (
                                        goods.goodsTotalWeight
                                    )}
                                </TableCell>


                                <TableCell
                                
                                    style={{
                                        whiteSpace: 'nowrap', // Prevent content from wrapping
                                        overflow: 'hidden',   // Hide overflowing content
                                        textOverflow: 'ellipsis', // Show ellipsis (...) for overflowing content
                                        minWidth: '100px',   // Set a minimum width for the cell
                                        width: 'auto'
                                    }}>

                                    {currentlyEditedRow === goods._id ? (
                                        <TextField
                                            value={editedGoods[goods._id]?.goodsTotalCBM || goods.goodsTotalCBM}
                                            onChange={(e) =>
                                                handleEditChange(goods._id, 'goodsTotalCBM', e.target.value)
                                            }
                                        />
                                    ) : (
                                        goods.goodsTotalCBM
                                    )}
                                </TableCell>

                                <TableCell>
                                    {currentlyEditedRow === goods._id ? (
                                        <FormControl>
                                            <InputLabel>Shipping Mode</InputLabel>
                                            <Select
                                                value={editedGoods[goods._id]?.goodsShippingMode || goods.goodsShippingMode}
                                                onChange={(e) =>
                                                    handleEditChange(goods._id, 'goodsShippingMode', e.target.value)
                                                }
                                            >
                                                <MenuItem value="Boat">Boat</MenuItem>
                                                <MenuItem value="Flight">Flight</MenuItem>
                                            </Select>
                                        </FormControl>
                                    ) : (
                                        goods.goodsShippingMode
                                    )}
                                </TableCell>


                                <TableCell>
                                    {currentlyEditedRow === goods._id ? (
                                        <FormControl>
                                            <InputLabel>Current Location</InputLabel>
                                            <Select
                                                value={editedGoods[goods._id]?.goodsCurrentCountrylocation || goods.goodsCurrentCountrylocation}
                                                onChange={(e) =>
                                                    handleEditChange(goods._id, 'goodsCurrentCountrylocation', e.target.value)
                                                }
                                            >
                                                <MenuItem value="China">China</MenuItem>
                                                <MenuItem value="Guinea">Guinea</MenuItem>
                                            </Select>
                                        </FormControl>
                                    ) : (
                                        goods.goodsCurrentCountrylocation
                                    )}
                                </TableCell>

                              
                                <TableCell 
                                
                                    style={{
                                        whiteSpace: 'nowrap', // Prevent content from wrapping
                                        overflow: 'hidden',   // Hide overflowing content
                                        textOverflow: 'ellipsis', // Show ellipsis (...) for overflowing content
                                        minWidth: '100px',   // Set a minimum width for the cell
                                        width: 'auto'
                                    }}
                                    >
                                    {currentlyEditedRow === goods._id ? (
                                        <TextField
                                            value={editedGoods[goods._id]?.senderName || goods.senderName}
                                            onChange={(e) =>
                                                handleEditChange(goods._id, 'senderName', e.target.value)
                                            }
                                        />
                                    ) : (
                                        goods.senderName
                                    )}
                                </TableCell>


                                <TableCell
                                    style={{
                                        whiteSpace: 'nowrap', // Prevent content from wrapping
                                        overflow: 'hidden',   // Hide overflowing content
                                        textOverflow: 'ellipsis', // Show ellipsis (...) for overflowing content
                                        minWidth: '100px',   // Set a minimum width for the cell
                                        width: 'auto'
                                    }}>

                                    {currentlyEditedRow === goods._id ? (
                                        <TextField
                                            value={editedGoods[goods._id]?.senderTel || goods.senderTel}
                                            onChange={(e) =>
                                                handleEditChange(goods._id, 'senderTel', e.target.value)
                                            }
                                        />
                                    ) : (
                                        goods.senderTel
                                    )}
                                </TableCell>

                                <TableCell 
                                
                                    style={{
                                        whiteSpace: 'nowrap', // Prevent content from wrapping
                                        overflow: 'hidden',   // Hide overflowing content
                                        textOverflow: 'ellipsis', // Show ellipsis (...) for overflowing content
                                        minWidth: '100px',   // Set a minimum width for the cell
                                        width: 'auto'
                                    }}> 
                                    
                                    {currentlyEditedRow === goods._id ? (
                                        <TextField
                                            value={editedGoods[goods._id]?.senderPaymentAmount || goods.senderPaymentAmount}
                                            onChange={(e) =>
                                                handleEditChange(goods._id, 'senderPaymentAmount', e.target.value)
                                            }
                                        />
                                    ) : (
                                        goods.senderPaymentAmount
                                    )}
                                </TableCell>
 
                                


                               

                                <TableCell>
                                    {currentlyEditedRow === goods._id ? (
                                        <TextField
                                            value={editedGoods[goods._id]?.senderComment || goods.senderComment}
                                            onChange={(e) =>
                                                handleEditChange(goods._id, 'senderComment', e.target.value)
                                            }
                                        />
                                    ) : (
                                        goods.senderComment
                                    )}
                                </TableCell>


                              


                                <TableCell 
                                
                                    style={{
                                        whiteSpace: 'nowrap', // Prevent content from wrapping
                                        overflow: 'hidden',   // Hide overflowing content
                                        textOverflow: 'ellipsis', // Show ellipsis (...) for overflowing content
                                        minWidth: '100px',   // Set a minimum width for the cell
                                        width: 'auto'
                                    }}>

                                    {currentlyEditedRow === goods._id ? (
                                        <TextField
                                            value={editedGoods[goods._id]?.date || goods.date}
                                            onChange={(e) =>
                                                handleEditChange(goods._id, 'date', e.target.value)
                                            }
                                        />
                                    ) : (
                                        goods.date
                                    )}
                                </TableCell>

                                <TableCell 
                                    style={{
                                        whiteSpace: 'nowrap', // Prevent content from wrapping
                                        overflow: 'hidden',   // Hide overflowing content
                                        textOverflow: 'ellipsis', // Show ellipsis (...) for overflowing content
                                        minWidth: '100px',   // Set a minimum width for the cell
                                        width: 'auto'
                                    }}
                                    
                                    >
                                    {currentlyEditedRow === goods._id ? (
                                        <TextField
                                            value={editedGoods[goods._id]?.deliveryDate || goods.deliveryDate}
                                            onChange={(e) =>
                                                handleEditChange(goods._id, 'deliveryDate', e.target.value)
                                            }
                                        />
                                    ) : (
                                        goods.deliveryDate
                                    )}
                                </TableCell>

                                <TableCell 
                                
                                    style={{
                                        whiteSpace: 'nowrap', // Prevent content from wrapping
                                        overflow: 'hidden',   // Hide overflowing content
                                        textOverflow: 'ellipsis', // Show ellipsis (...) for overflowing content
                                        minWidth: '100px',   // Set a minimum width for the cell
                                        width: 'auto'
                                    }}
                                    
                                    >
                                    {currentlyEditedRow === goods._id ? (
                                        <TextField
                                            value={editedGoods[goods._id]?.receptionDate || goods.receptionDate}
                                            onChange={(e) =>
                                                handleEditChange(goods._id, 'receptionDate', e.target.value)
                                            }
                                        />
                                    ) : (
                                        goods.receptionDate
                                    )}
                                </TableCell>



                                


                                {/* Repeat the same pattern for other columns
                                <TableCell>
                                    {currentlyEditedRow === goods._id ? (

                                        <>
                                            <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => saveEditedGoods(goods._id)}
                                        >
                                            Save
                                        </Button>


                                            <Button
                                                variant="contained"
                                                onClick={() => cancelEdit(goods._id)}
                                                style={{ backgroundColor: 'darkred', color: 'white' , marginTop: '5px' }}
                                            >
                                                Cancel
                                            </Button>

                                        </>
                                      

                                        
                                    ) : (
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => toggleEdit(goods._id)}
                                        >
                                            Edit
                                        </Button>
                                    )}
                                </TableCell> */}

                             
                            </TableRow>
                        ))}
                      
                </TableBody>
                
            </Table>
              
        </TableContainer>
      
        </div>
    );
}

export default GoodsTable;