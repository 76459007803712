import React, { useEffect, useState } from 'react';
import {
    Table,
    Typography,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TextField,
    Grid,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ClientTableByAgentTel = () => {
    const [clients, setClients] = useState([]);
    const [filters, setFilters] = useState({
        name: '',
        tel: '',
        address: '',
        status: '',
        type: '',
        startDate: '',
        endDate: '',
    });
    const [selectedClientId, setSelectedClientId] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);

    useEffect(() => {
        // Fetch data from the API with authentication token
        const token = localStorage.getItem('token'); // Replace with your actual auth token

        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/clients`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                if (response.status === 200) {
                    setClients(response.data);
                } else {
                    toast.error('No Authorization', {
                        position: 'top-center',
                        autoClose: 5000,
                    });
                    console.error('Error fetching data:', response.statusText);
                }
            } catch (error) {
                toast.error('No Authorization', {
                    position: 'top-center',
                    autoClose: 5000,
                });
                console.error('Error fetching data:', error.message);
            }
        };

        fetchData();
    }, [selectedClientId]);

    const applyFilters = (client) => {
        const { name, tel, address, status, type, startDate, endDate } = filters;

        const isDateInRange =
            (!startDate || new Date(client.date).getTime() >= new Date(startDate).getTime()) &&
            (!endDate || new Date(client.date).getTime() <= new Date(endDate).getTime());

        return (
            client.firstName.toLowerCase().includes(name.toLowerCase()) &&
            client.phoneNumber.toLowerCase().includes(tel.toLowerCase()) &&
            client.address.toLowerCase().includes(address.toLowerCase()) &&
            client.status.toLowerCase().includes(status.toLowerCase()) &&
            client.type.toLowerCase().includes(type.toLowerCase()) &&
            isDateInRange
        );
    };

    const handleDeleteClient = async () => {
        const token = localStorage.getItem('token');

        try {
            await axios.delete(`${process.env.REACT_APP_BASE_URL}/clients/${selectedClientId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            toast.success('Client deleted successfully!', {
                position: 'top-center',
                autoClose: 5000,
            });

            setSelectedClientId(null);
            setOpenDialog(false);
        } catch (error) {
            toast.error('Not Authorize deleting client', {
                position: 'top-center',
                autoClose: 5000,
            });
            console.log(error);
            console.error('Error deleting client:', error.message);
        }
    };

    const handleOpenDialog = (clientId) => {
        setSelectedClientId(clientId);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setSelectedClientId(null);
        setOpenDialog(false);
    };

    // Filter and sort the clients by date
    const filteredClients = clients
        .filter(applyFilters)
        .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());


    const [showFilters, setShowFilters] = useState(false);

    const toggleFilters = () => {
        setShowFilters((prevShowFilters) => !prevShowFilters);
    };

    const formatDateToFrench = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        if (isNaN(date.getTime())) return 'Date invalide'; // Return 'Invalid date' in French
        return date.toLocaleDateString('fr-FR', {
            day: 'numeric',
            month: 'long',
            year: 'numeric'
        });
    };


    return (
        <Grid>
            <Typography variant="h6" style={{ fontSize: '1.5rem', fontWeight: 'bold', margin: '12px 0 12px 0px' }}>
                All Clients 📅 
                Total : {clients.length}
            </Typography>

            <Button
                variant="contained"
                onClick={toggleFilters}
                style={{
                    backgroundColor: 'blue',
                    color: 'white',
                    minWidth: '120px',
                    marginBottom: '10px',
                }}
            >
                {showFilters ? 'Hide Filters' : 'Show Filters'}
            </Button>
            {showFilters && (
                
         <Grid container spacing={2} mb={2}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                    label="Name"
                    variant="outlined"
                    size="small"
                    margin="dense"
                    fullWidth
                    value={filters.name}
                    onChange={(e) => setFilters({ ...filters, name: e.target.value })}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                    label="Tel"
                    variant="outlined"
                    size="small"
                    margin="dense"
                    fullWidth
                    value={filters.tel}
                    onChange={(e) => setFilters({ ...filters, tel: e.target.value })}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                    label="Address"
                    variant="outlined"
                    size="small"
                    margin="dense"
                    fullWidth
                    value={filters.address}
                    onChange={(e) => setFilters({ ...filters, address: e.target.value })}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                    label="Status"
                    variant="outlined"
                    size="small"
                    margin="dense"
                    fullWidth
                    value={filters.status}
                    onChange={(e) => setFilters({ ...filters, status: e.target.value })}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                    label="Type"
                    variant="outlined"
                    size="small"
                    margin="dense"
                    fullWidth
                    value={filters.type}
                    onChange={(e) => setFilters({ ...filters, type: e.target.value })}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                    label="Start Date"
                    InputLabelProps={{ shrink: true }}
                    type="date"
                    variant="outlined"
                    size="small"
                    margin="dense"
                    fullWidth
                    value={filters.startDate}
                    onChange={(e) => setFilters({ ...filters, startDate: e.target.value })}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                    label="End Date"
                    InputLabelProps={{ shrink: true }}
                    type="date"
                    variant="outlined"
                    size="small"
                    margin="dense"
                    fullWidth
                    value={filters.endDate}
                    onChange={(e) => setFilters({ ...filters, endDate: e.target.value })}
                />
            </Grid>
        </Grid>
            )}

          

            {/* Client Table */}
            <TableContainer 
                style={ {
                    height: '600px'
                } }
            
            
            component={Paper}>
                <Table>
                    <TableHead
                        style={{
                            backgroundColor: '#78AEF9',
                            position: 'sticky',
                            top: 0,
                            zIndex: 1000,
                            fontWeight: 'bold',
                        }}
                    >

                       
                

                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>First Contact</TableCell>
                            <TableCell>Tel</TableCell>
                            <TableCell>Business</TableCell>
                            <TableCell>Activity</TableCell>
                            <TableCell>Address</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Type</TableCell>
                            <TableCell>Comment</TableCell>
                            <TableCell>Registered Date in the System</TableCell>
                            <TableCell>Registered By</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody
                   
                    >
                        {filteredClients.map((client, index) => (
                            <TableRow
                                key={client._id}
                                style={{
                                    backgroundColor: index % 2 === 0 ? '#ADD8E6' : '#73c3e6',
                                }}
                            >
                                <TableCell>{client.firstName} {client.lastName}</TableCell>
                             

                                <TableCell>
                                    {/* {`${new Date(client.dateOfFirstContact).toLocaleDateString(undefined, { weekday: 'long' })}, ${new Date(client.dateOfFirstContact).toLocaleDateString()} ${new Date(client.dateOfFirstContact).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`} */}
                                    {formatDateToFrench(client.dateOfFirstContact)}
                               
                                </TableCell>


                                <TableCell>{client.phoneNumber}</TableCell>
                                <TableCell>{client.business}</TableCell>
                                <TableCell>{client.activity}</TableCell>
                                <TableCell>{client.address}</TableCell>
                                <TableCell>{client.status}</TableCell>
                                <TableCell>{client.type}</TableCell>
                                <TableCell>{client.comment}</TableCell>
                                <TableCell>
                                    
                                    {/* {new Date(client.date).toLocaleString()} */}
                                    {formatDateToFrench(client.date)}
                                    
                                    </TableCell>
                                <TableCell>{`${client.registeredBy} (${client.registeredByTel})`}</TableCell>
                                <TableCell>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        onClick={() => handleOpenDialog(client._id)}
                                    >
                                        Delete
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* Delete Confirmation Dialog */}
            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle>Delete Confirmation</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this client?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDeleteClient} color="primary" autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>

            <ToastContainer position="top-center" />
        </Grid>
    );
};

export default ClientTableByAgentTel;
