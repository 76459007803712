import React, { useEffect, useRef } from 'react';
import * as echarts from 'echarts';

function PieChart() {
    const chartRef = useRef(null);

    useEffect(() => {
        // Fetch data from the API with the auth token from local storage
        const authToken = localStorage.getItem('token');
        fetch(`${process.env.REACT_APP_BASE_URL}/expenses/displayAll`, {
            headers: {
                Authorization: `Bearer ${authToken}`,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                // Extract the data you need for the Pie chart (transactionType)
                const transactionData = data.reduce((acc, item) => {
                    // Consider only "Added" and "Reduced" types
                    if (item.transactionType === 'added' || item.transactionType === 'reduced') {
                        // Increment the value based on the transaction type
                        if (!acc[item.transactionType]) {
                            acc[item.transactionType] = 1;
                        } else {
                            acc[item.transactionType]++;
                        }
                    }
                    return acc;
                }, {});

                // Define custom colors for each type
                const colors = {
                    Added: '#0088FF',  // Blue for "Added"
                    Reduced: '#FF0000',  // Red for "Reduced"
                };

                // Format data for ECharts
                const formattedData = Object.keys(transactionData).map((type) => ({
                    name: type,
                    value: transactionData[type],
                    itemStyle: { color: colors[type] },
                }));

                // Initialize ECharts chart
                const myChart = echarts.init(chartRef.current);

                const option = {
                    title: {
                        text: 'Transaction Types',
                        left: 'center',
                    },
                    tooltip: {
                        trigger: 'item',
                        formatter: '{a} <br/>{b}: {c} ({d}%)',
                    },
                    legend: {
                        orient: 'vertical',
                        left: 10,
                        data: Object.keys(transactionData),
                    },
                    series: [
                        {
                            name: 'Transaction Type',
                            type: 'pie',
                            radius: '50%',
                            center: ['50%', '60%'],
                            data: formattedData,
                            emphasis: {
                                itemStyle: {
                                    shadowBlur: 10,
                                    shadowOffsetX: 0,
                                    shadowColor: 'rgba(0, 0, 0, 0.5)',
                                },
                            },
                        },
                    ],
                };

                // Set the option and render the chart
                myChart.setOption(option);
            })
            .catch((error) => {
                console.error('Error fetching data: ', error);
            });
    }, []);

    return <div ref={chartRef} style={{ width: '100%', height: '400px' }}></div>;
}

export default PieChart;
