import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import axios from 'axios';
import {
    Container, Typography, TextField, Button, Link, CircularProgress, FormControl,
    InputLabel, MenuItem,
Select } from '@mui/material';

function Ticket() {
    const [name, setName] = useState('');
    const [typeOfTicket, setTypeOfTicket] = useState('Support Request');
    const [niveauDurgence, setNiveauDurgence] = useState('Moin Urgent');
    const [ticketContent, setTicketContent] = useState('');

    
    const [isSubmitting, setIsSubmitting] = useState(false);
   

    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        console.log(`name ${name}`);
        console.log(`typeOfTicket ${typeOfTicket}`);
        console.log(`ticketContent ${ticketContent}`);
        const authToken = localStorage.getItem('token');
        const authorizationHeader = `Bearer ${authToken}`;

        const ticketData = {
            name,
            ticketContent,
            typeOfTicket,
            niveauDurgence
        }

        if (
            !ticketData.name ||
            !ticketData.ticketContent ||
            !ticketData.typeOfTicket ||
            !ticketData.niveauDurgence ||
            !ticketData.name          
        ) {
            toast.error('Please fill in all required fields', {
                position: 'top-center',
                autoClose: 5000, // 5 seconds
            });
            setIsSubmitting(false);

            return; // Stop the submission if any required field is empty
        }

        console.log(ticketData);
        try {
         
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/ticket`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: authorizationHeader,
                },
                body: JSON.stringify(ticketData), // JSON.stringify the body
            });

            const userRole = localStorage.getItem('userRole');
 

            // Handle success
            toast.success('Ticket Successfully', {
                position: 'top-center',
                autoClose: 5000, // 5 seconds
            });

            // Delay the navigation by 3 seconds
            setTimeout(() => {

                const routeMapping = {
                    admin: '/userTicketsTableForUsers',
                    employee: '/userTicketsTableForUsers',
                    sender: '/userTicketsTableForUsers',
                    client: '/userTicketsTableForUsers',
                };

                const destinationRoute = routeMapping[userRole] || '/userTicketsTableForUsers';

                // navigate(destinationRoute, { replace: true });
                window.location.href = `${destinationRoute}`;


            }, 3000);

        } catch (error) {
            toast.error(`${error}`, {
                position: 'top-center',
                autoClose: 5000, // 5 seconds
            });
            console.error('Ticket failed:', error);
        }
    };

    const containerStyle = {
        marginTop: '2rem',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    };

    const formStyle = {
        width: '100%',
        marginTop: '2rem',
    };

    const buttonStyle = {
        marginTop: '2rem',
    };

    const goToRegistrationPage = () => {
        navigate('/register')
    }

    const handleTicketContentChange = (e) => {
        setTypeOfTicket(e.target.value);
    };

    return (
        <Container component="main" maxWidth="xs" style={containerStyle}>
            <Typography variant="h5"> Submit New Ticket  </Typography>
        

            <form onSubmit={handleSubmit} style={formStyle}>
                <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    label="Title"
                    type="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
               

                <FormControl style={{ marginBottom: '16px', minWidth: '100%' }}>
                    <InputLabel>Type of Ticket</InputLabel>
                    <Select
                        label="Raison"
                        name="typeOfTicket"
                        value={typeOfTicket}
                        onChange={(e) => setTypeOfTicket(e.target.value)}
                        fullWidth

                    >
                        <MenuItem value="Support Request"> Support Request </MenuItem>
                        <MenuItem value="Access Request"> Access Request </MenuItem>
                        <MenuItem value="Suggestion"> Suggestion </MenuItem>
                        <MenuItem value="Password Forgoteen"> Password Forgoteen </MenuItem>

                        {/* Add more options as needed */}
                    </Select>
                </FormControl>


                <FormControl style={{ marginBottom: '16px', minWidth: '100%' }}>
                    <InputLabel> niveau D'Urgence * </InputLabel>
                    <Select
                        label="niveauDurgence"
                        name="niveauDurgence"
                        value={niveauDurgence}

                        onChange={(e) => setNiveauDurgence(e.target.value)}

                        fullWidth

                    >
                        <MenuItem value="Urgent">Urgent</MenuItem>
                        <MenuItem value="Moin Urgent">Moin Urgent</MenuItem>
                        <MenuItem value="Pas Urgent">Pas Urgent</MenuItem>
                    </Select>
                </FormControl>


                <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    label="Content Details / Description"
                    type="teckitContent"
                    value={ticketContent}
                    onChange={(e) => setTicketContent(e.target.value)}
                />

            
                

                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    // style={buttonStyle}
                    style={{ marginTop: '20px', position: 'relative' }} // Position relative for CircularProgress
                    disabled={isSubmitting} // Disable button while submitting
                >
                    {isSubmitting ? (
                        <CircularProgress size={24} color="inherit" /> // Show CircularProgress while submitting
                    ) : (
                        'Submit Ticket'
                    )}
                </Button>
            </form>
            <ToastContainer position="top-center" autoClose={5000} />

        </Container>
    );
}

export default Ticket;
