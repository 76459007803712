import React, { useEffect, useState } from 'react';
import axios from 'axios';
import * as XLSX from 'xlsx';                    
import {
    Table,
    TableBody, FormControl,
InputLabel,
    TableCell,
    TableContainer,
    TableHead, Stack,
    TableRow,
    Paper,
    Select, Grid,
    MenuItem, Typography, CircularProgress, TextareaAutosize,
    Button,
    TextField, // Add TextField for date inputs
} from '@mui/material';

import { useNavigate } from 'react-router-dom';

import Dropzone from 'react-dropzone';
import { Image, Video } from 'cloudinary-react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ImageModalExpenses from '../modals/ImageModalExpenses';


function ExpenseTable() {
    const [selectedBudgetNameFilter, setSelectedBudgetNameFilter] = useState('All');

    const [expenses, setExpenses] = useState([]);
    const [selectedTypeFilter, setSelectedTypeFilter] = useState('reduced');
    const [selectedCategoryFilter, setSelectedCategoryFilter] = useState('All');
    const [selectedbudgetName, setSelectedbudgetName] = useState('All');
    const [startDate, setStartDate] = useState(''); // State for start date filter
    const [endDate, setEndDate] = useState(''); // State for end date filter
    const navigate = useNavigate(); // useNavigate hook
    const [editExpenseId, setEditExpenseId] = useState(null); // State to track the expense being edited
    const [editedExpenseData, setEditedExpenseData] = useState({}); // State to store edited data
 
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [uploadedImages, setUploadedImages] = useState([]);
    const [updateTable, setUpdateTable] = useState(0)


    // Function to fetch expenses data
    const fetchExpensesData = () => {
        const authToken = localStorage.getItem('token');
        axios
            .get(`${process.env.REACT_APP_BASE_URL}/expenses/displayAll`, {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            })
            .then((response) => {
                const sortedExpenses = response.data.sort(
                    (a, b) => new Date(b.date) - new Date(a.date)
                );
                // Filter out expenses with type 'subvention'
                const noPaiementFournisseur = sortedExpenses.filter(
                    (expense) => expense.type !== 'Paiement Fournisseur' && expense.type !== 'SubvensionRembourse'
                );
                setExpenses(noPaiementFournisseur);
            })
            .catch((error) => {
                console.error('Error fetching expenses:', error);
            });
    };

    useEffect(() => {
        // Fetch data from the API with the auth token
        fetchExpensesData();
    }, [updateTable]);

    // Function to edit an expense
    const handleEditExpense = (expenseId) => {
        // Find the selected expense by its ID
        const selectedExpense = expenses.find((expense) => expense._id === expenseId);
        if (!selectedExpense) {
            console.error('Expense not found');
            toast.error('Expense not found');
            return;
        }
        setEditExpenseId(expenseId);
        // Store the data of the selected expense in editedExpenseData
        setEditedExpenseData(selectedExpense);
    };

    // Function to update the edited expense
    const handleUpdateExpense = async () => {
        setIsSubmitting(true)
        const authToken = localStorage.getItem('token');
        try {
            await axios.put(`${process.env.REACT_APP_BASE_URL}/expenses/update/${editExpenseId}`, editedExpenseData, {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            });

            // Clear the edit mode
            setEditExpenseId(null);
            setEditedExpenseData({});
            toast.success('Expense updated successfully');
            setIsSubmitting(false)


            // Fetch updated data after a successful edit
            fetchExpensesData();
        } catch (error) {
            console.error('Error updating expense:', error);
            toast.error('Error updating expense');
        }
    };


    function formatCurrency(amount) {
        return amount.toLocaleString('en-US', {
            style: 'currency',
            currency: 'GNF',
            minimumFractionDigits: 0,
        });
    }

    // Function to filter expenses based on selected filters and date range
    const filteredExpenses = expenses.filter((expense) => {
        const typeMatch = selectedTypeFilter === 'All' || expense.transactionType === selectedTypeFilter;
        const categoryMatch = selectedCategoryFilter === 'All' || expense.type === selectedCategoryFilter;
        const budgetNameMatch = selectedBudgetNameFilter === 'All' || expense.budgetName === selectedBudgetNameFilter;

        // Date range filter
        const startDateObj = new Date(startDate);
        const endDateObj = new Date(endDate);

        const dateMatch =
            (!startDate || new Date(expense.date) >= startDateObj) &&
            (!endDate || new Date(expense.date) <= endDateObj);

        return typeMatch && categoryMatch && budgetNameMatch && dateMatch;
    });


    function generateExcelData() {
        const data = [];
        data.push([
            'Date',
            'ByWho',
            'ToWho',
            'Type',
            'Category',
            'Item',
            'Quantity',
            'Price',
            'Total',
            'From Account',
        ]);

        filteredExpenses.forEach((expense) => {
            data.push([
                new Date(expense.date).toLocaleDateString(),
                expense.createdByWho && expense.createdByWho.name, 
                expense.byWho,
                expense.toWhom,
                expense.transactionType,
                expense.type,
                expense.item.unit,
                expense.item.quantity,
                expense.item && typeof expense.item.price !== 'undefined'
                    ? expense.item.price.toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'GNF',
                        minimumFractionDigits: 2,
                    })
                    : 'N/A',
                expense.item && typeof expense.item.total !== 'undefined'
                    ? expense.item.total.toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'GNF',
                        minimumFractionDigits: 2,
                    })
                    : 'N/A',
                expense.budgetName,
            ]);
        });

        return data;
    }

    function handleDownloadExcel() {
        const data = generateExcelData();
        const ws = XLSX.utils.aoa_to_sheet(data);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Expenses');
        XLSX.writeFile(wb, 'expenses.xlsx');
    }

    const resetFilters = () => {
        setSelectedTypeFilter('All');
        setSelectedCategoryFilter('All');
        setSelectedbudgetName('All')
        setStartDate('');
        setEndDate('');
    };
 
   
    // Function to delete an expense by its ID
    const handleDeleteExpense = async (expenseId) => {
        const authToken = localStorage.getItem('token');

        // Use window.confirm() to show a confirmation dialog
        const isConfirmed = window.confirm('Are you sure you want to delete this expense?');

        if (!isConfirmed) {
            // User cancelled the delete operation
            return;
        }

        try {
            // Find the selected expense by its ID
            const selectedExpense = expenses.find((expense) => expense._id === expenseId);
            if (!selectedExpense) {
                console.error('Expense not found');
                toast.error('Expense not found');
                return;
            }

            const requestBody = {
                expenseData: {
                    type: selectedExpense.type,
                    item: {
                        itemName: selectedExpense.item.itemName,
                        unit: selectedExpense.item.unit,
                        quantity: selectedExpense.item.quantity,
                        price: selectedExpense.item.price,
                    },
                    status: selectedExpense.status,
                    imagesLinks: selectedExpense.imagesLinks,
                    toWhom: selectedExpense.toWhom,
                    comment: selectedExpense.comment,
                    budgetId: selectedExpense.budgetId,
                    budgetName: selectedExpense.budgetName,
                    transactionType: selectedExpense.transactionType,
                },
            };

            await axios.delete(`${process.env.REACT_APP_BASE_URL}/expenses/delete/${expenseId}`, {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
                data: requestBody, // Send the selected expense data in the request body
            });

            // Remove the deleted expense from the state
            setExpenses((prevExpenses) =>
                prevExpenses.filter((expense) => expense._id !== expenseId)
            );

            toast.success('Expense deleted successfully');
        } catch (error) {
            console.error('Error deleting expense:', error);
            toast.error('Error deleting expense');
        }
    };



    
    // Add this CSS class in your stylesheet or within your component's styles
    const textFieldStyle = {
        width: '100%', // Set the width to 100%
    };


    // Calculate the sum of all the totals in the filtered expenses
    const totalSum = filteredExpenses.reduce((acc, expense) => {
        return acc + (expense.item.total || 0);
    }, 0);


    const totalfraisRetrait = filteredExpenses.reduce((acc, expense) => {
        return acc + (expense.fraisDeRetrait || 0);
    }, 0);


    const totalfraisDepot = filteredExpenses.reduce((acc, expense) => {
        return acc + (expense.fraisDeDepot || 0);
    }, 0);


    const [showFilters, setShowFilters] = useState(false);

    const toggleFilters = () => {
        setShowFilters((prevShowFilters) => !prevShowFilters);
    };





    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedRowId, setSelectedRowId] = useState(null);
   
    const handleImageClick = (imageUrl, rowId) => {
        setSelectedImage(imageUrl);
        setSelectedRowId(rowId);
    };

    const handleCloseModal = () => {
        setSelectedImage(null);
        setSelectedRowId(null);
    };

    // Helper function to validate image link
    // Helper function to validate image link
    const isValidImageLink = (link) => {
        const imageLinkRegex = /^https:\/\/.+\.([jJ][pP][gG]|[jJ][pP][eE][gG]|[pP][nN][gG]|[gG][iI][fF])$/;
        return imageLinkRegex.test(link);
    };

    const handleGoToNewExpenseForm = ( ) => {
        navigate('/ExpenseForm')
    }


     



    const cldNme = `${process.env.REACT_APP_CLOUDINARY_CLDNME_URL}`;
    const handleImageUpload = async (files) => {
        try {
            const uploadPromises = files.map((file) => {
                const formData = new FormData();
                formData.append('file', file);
                formData.append('upload_preset', `${process.env.REACT_APP_CLOUDINARY_PRESET}`);

                return fetch(`https://api.cloudinary.com/v1_1/${cldNme}/image/upload`, {
                    method: 'POST',
                    body: formData,
                })
                    .then((response) => response.json())
                    .then((data) => data.secure_url);
            });

            const newImageUrls = await Promise.all(uploadPromises);
            const updatedImageUrls = [...editedExpenseData.imagesLinks, ...newImageUrls]; // Append new URLs to existing ones
            setUploadedImages(newImageUrls);
            setEditedExpenseData({
                ...editedExpenseData,
                imagesLinks: updatedImageUrls, // Update with the combined array
            });
            toast.success('Image(s) uploaded successfully', {
                position: 'top-center',
                autoClose: 5000,
            });
        } catch (error) {
            console.error('Error uploading images:', error);
        }
    };


    const handleImageLinksChange = (acceptedFiles) => {
        handleImageUpload(acceptedFiles);
    };

    const formatDateToFrench = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        if (isNaN(date.getTime())) return 'Date invalide'; // Return 'Invalid date' in French
        return date.toLocaleDateString('fr-FR', {
            day: 'numeric',
            month: 'long',
            year: 'numeric'
        });
    };

    return (
        <div>
            <Typography variant="h6" style={{fontSize: '2rem' , fontWeight: 'bold' }}>

            Table des Depenses  💰
            </Typography>
            <Button
            fullWidth
            style={{
                backgroundColor: 'green',
                color: 'white',
                margin: '10px 0 10px 0'
            }}
                onClick={handleGoToNewExpenseForm}
            >
                Nouvelle Depense ?
            </Button>

            <Button
                variant="contained"
                onClick={toggleFilters}
                style={{
                    backgroundColor: 'blue',
                    color: 'white',
                    minWidth: '120px',
                    marginBottom: '10px',
                }}
            >
                {showFilters ? 'Hide Filters' : 'Show Filters'}
            </Button>

            

            {showFilters && (

            <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', gap: '16px',marginBottom: '10px' }}>
                    <Grid container spacing={2}>
                        {/* <Grid item xs={6} md={6}>

                            <FormControl fullWidth style={{ minWidth: '150px', flex: '1' }}>
                                <InputLabel
                                    style={{
                                        backgroundColor: 'white',
                                        padding: '0px 12px 0px 12px',
                                    }}
                                >Type</InputLabel>
                                <Select
                                    name="shippingMode"
                                    value={selectedTypeFilter}
                                    onChange={(e) => setSelectedTypeFilter(e.target.value)}
                                >
                                    <MenuItem value="All">All</MenuItem>
                                    <MenuItem value="reduced">Reduced</MenuItem>
                                    <MenuItem value="added">Added</MenuItem>
                                
                                </Select>
                            </FormControl>
                        </Grid> */}


                        <Grid item xs={6} md={6}>
                            <FormControl fullWidth style={{ minWidth: '150px', flex: '1' }}>
                                <InputLabel
                                    style={{
                                        backgroundColor: 'white',
                                        padding: '0px 12px 0px 12px',
                                    }}
                                >Category</InputLabel>
                                <Select
                                    name="shippingMode"
                                    value={selectedCategoryFilter}
                                    onChange={(e) => setSelectedCategoryFilter(e.target.value)}
                                >

                                    <MenuItem value="Food">Food</MenuItem>
                                    <MenuItem value="Prêt Employé">Prêt Employé</MenuItem>
                                    <MenuItem value="Transport">Transport</MenuItem>
                                    <MenuItem value="Service">Service</MenuItem>
                                    <MenuItem value="Loyer">Loyer</MenuItem>
                                    <MenuItem value="Salaire">Salaire</MenuItem>
                                    <MenuItem value="Paiment Fournisseur">Paiment Fournisseur</MenuItem>
                                    <MenuItem value="Bien">Bien</MenuItem>
                                    <MenuItem value="Prime">Prime</MenuItem>
                                    <MenuItem value="Consommable">Consommable</MenuItem>
                                    <MenuItem value="Internet">Internet</MenuItem>
                                    <MenuItem value="Dividende">Dividende</MenuItem>
                                    <MenuItem value="Crédit Télephone">Crédit Télephone</MenuItem>
                                    <MenuItem value="Autres">Autres</MenuItem>
                                </Select>
                            </FormControl>

                        </Grid>

                        <Grid item xs={6} md={6}>


                            <FormControl
                                fullWidth={true}

                            sx={{ m: 1, minWidth: 120 }}
                            style={{
                                minWidth: '150px', flex: '1',marginLeft: '-1px' 
                            }}
                            >
                                <InputLabel
                                style={{
                                    backgroundColor: 'white',
                                }}
                                
                                id="budgetNameFilterLabel">Budget Name</InputLabel>
                                <Select
                                    labelId="budgetNameFilterLabel"
                                    id="budgetNameFilter"
                                    value={selectedBudgetNameFilter}
                                    onChange={(e) => setSelectedBudgetNameFilter(e.target.value)}
                                >
                                    <MenuItem value="All">All</MenuItem>
                                    {/* Add other budget names dynamically based on your data */}
                                    {Array.from(new Set(expenses.map((expense) => expense.budgetName))).map((budgetName) => (
                                        <MenuItem key={budgetName} value={budgetName}>
                                            {budgetName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                        </Grid>




                        <Grid item xs={6} md={6}>

                            <TextField
                                fullWidth
                                type="date"
                                label="Start date"
                                InputLabelProps={{ shrink: true }}

                                value={startDate}
                                onChange={(e) => setStartDate(e.target.value)}
                                style={{ minWidth: '120px' }}
                            />
                            
                        </Grid>

                        <Grid item xs={6} md={6}>
                            <TextField
                                fullWidth
                                type="date"
                                label="End date"
                                InputLabelProps={{ shrink: true }}
                                value={endDate}
                                onChange={(e) => setEndDate(e.target.value)}
                                style={{ minWidth: '120px' }}
                            />

                           

                        </Grid>



                        <Grid item xs={6} md={6}>
                            <Button
                                fullWidth
                                variant="contained"
                                style={{
                                    backgroundColor: 'darkred',
                                    color: 'white',
                                    minWidth: '120px',
                                    flex: '1', // Occupy available width on small screens
                                }}
                                onClick={resetFilters}
                            >
                                Reset Filters 
                            </Button>
                           

                        </Grid>




                        <Grid item xs={6} md={6}>
                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                onClick={handleDownloadExcel}
                                style={{
                                    minWidth: '120px',
                                    flex: '1', // Occupy available width on small screens
                                }}
                            >
                                Download Excel
                            </Button>

                        </Grid>







                    </Grid>
              
                
                   

                 
 
                <div>
                   
                </div>
             
               
                <div >
                
                    <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                        Total Sum: {formatCurrency(totalSum)}
                    </Typography>
                </div>

                <div >

                    <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                        Total of All Retrait: {formatCurrency(totalfraisRetrait)}
                    </Typography>
                </div>

                <div >

                    <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                        Total of All Depot: {formatCurrency(totalfraisDepot)} 
                    </Typography>
                </div>

            </div>
            )}


            {/* <TableContainer component={Paper}> */}
            <TableContainer component={Paper} style={{ backgroundColor: '#f2f2f2', height: '750px' }}>
                
                <Table>
                    <TableHead
                        style={{
                            backgroundColor: '#78AEF9',
                            position: 'sticky',
                            top: 0,
                            zIndex: 1000,
                            fontWeight: 'bold', // Apply bold font
                        }}
                    >
                        <TableRow>
                            {/* <TableCell  style={{
                fontSize: '0.96rem',
                fontFamily: 'monospace',
                fontWeight: 'bold',
            }}>Account</TableCell> */}

                            <TableCell style={{
                                fontSize: '0.96rem',
                                fontFamily: 'monospace',
                                fontWeight: 'bold',
                            }}>Transaction Category</TableCell>
            

                            <TableCell style={{
                                fontSize: '0.96rem',
                                fontFamily: 'monospace',
                                fontWeight: 'bold',
                            }}>Date</TableCell>

                            <TableCell  style={{
                fontSize: '0.96rem',
                fontFamily: 'monospace',
                fontWeight: 'bold',
            }}>Register By</TableCell>
                            <TableCell  style={{
                fontSize: '0.96rem',
                fontFamily: 'monospace',
                fontWeight: 'bold',
            }}>By Who</TableCell>
                            <TableCell  style={{
                fontSize: '0.96rem',
                fontFamily: 'monospace',
                fontWeight: 'bold',
            }}>To Whom</TableCell>
                            {/* <TableCell  style={{
                fontSize: '0.96rem',
                fontFamily: 'monospace',
                fontWeight: 'bold',
            }}>Comment</TableCell> */}

                            <TableCell  style={{
                fontSize: '0.96rem',
                fontFamily: 'monospace',
                fontWeight: 'bold',
            }}>Type</TableCell>
                            {/* <TableCell  style={{
                fontSize: '0.96rem',
                fontFamily: 'monospace',
                fontWeight: 'bold',
            }}>Category</TableCell> */}
                            <TableCell  style={{
                fontSize: '0.96rem',
                fontFamily: 'monospace',
                fontWeight: 'bold',
            }}>Description</TableCell>
                            <TableCell  style={{
                fontSize: '0.96rem',
                fontFamily: 'monospace',
                fontWeight: 'bold',
            }}>Item Unit</TableCell>
                            <TableCell  style={{
                fontSize: '0.96rem',
                fontFamily: 'monospace',
                fontWeight: 'bold',
            }}>Quantity</TableCell>
                            <TableCell  style={{
                fontSize: '0.96rem',
                fontFamily: 'monospace',
                fontWeight: 'bold',
            }}>Price</TableCell>
                            <TableCell  style={{
                fontSize: '0.96rem',
                fontFamily: 'monospace',
                fontWeight: 'bold',
            }}>Total</TableCell>
                            <TableCell  style={{
                fontSize: '0.96rem',
                fontFamily: 'monospace',
                fontWeight: 'bold',
            }}>From Account</TableCell>
                            <TableCell  style={{
                fontSize: '0.96rem',
                fontFamily: 'monospace',
                fontWeight: 'bold',
            }}>Mode De Paiement </TableCell>
                            <TableCell  style={{
                fontSize: '0.96rem',
                fontFamily: 'monospace',
                fontWeight: 'bold',
            }}>Frai de Retrait</TableCell>
                            <TableCell  style={{
                fontSize: '0.96rem',
                fontFamily: 'monospace',
                fontWeight: 'bold',
            }}>Frai de Depot</TableCell>
                            <TableCell style={{
                                fontSize: '0.96rem',
                                fontFamily: 'monospace',
                                fontWeight: 'bold',
                            }}>imagesLinks</TableCell>
                            
                            <TableCell  style={{
                fontSize: '0.96rem',
                fontFamily: 'monospace',
                fontWeight: 'bold',
            }}>Edit</TableCell>
                            <TableCell  style={{
                fontSize: '0.96rem',
                fontFamily: 'monospace',
                fontWeight: 'bold',
            }}>Delete</TableCell>
                        </TableRow>

                    </TableHead>
                    <TableBody>
                        {filteredExpenses.length > 0 ? (
                            filteredExpenses.map((expense,index) => {
                                return (

                                    <TableRow
                                        key={expense._id}
                                        style={{
                                            backgroundColor: index % 2 === 0 ? '#ADD8E6' : '#73c3e6', // Use the color codes
                                        }}
                                    >

                                        {/* <TableCell>
                                            {expense.budgetName}
                                        </TableCell> */}
                                        <TableCell>
                                            {expense.transactionType === 'added' ? 'Revenu' : 'Depense'}
                                        </TableCell>

                                       
                                        <TableCell>
                                            {editExpenseId === expense._id ? (
                                                <TextField
                                                    type="date"
                                                    value={editedExpenseData.date}
                                                    onChange={(e) => setEditedExpenseData({
                                                        ...editedExpenseData,
                                                        date: e.target.value,
                                                    })} />
                                            ) : (
                                                // new Date(expense.date).toLocaleDateString()
                                                formatDateToFrench(expense.date)
                                            )}
                                        </TableCell>

                                        <TableCell>{expense.createdByWho ? expense.createdByWho.name : 'BAH fatoumata Lamarana'}</TableCell>
                   

                                        <TableCell style={{ width: '300px' }}>
                                            {editExpenseId === expense._id ? (

                                                <div style={{ width: "150px" }}>

                                                    <TextField
                                                        value={editedExpenseData.byWho}

                                                        onChange={(e) => setEditedExpenseData({
                                                            ...editedExpenseData,
                                                            byWho: e.target.value,
                                                        })}
                                                        fullWidth // Set the fullWidth prop to true
                                                    />
                                                </div>


                                            ) : (
                                                        expense.byWho ? expense.byWho : 'BAH fatoumata Lamarana' // Check if createdByWho is defined before accessing 'name'

                                                    )}
                                        </TableCell>



                                        <TableCell style={{ width: '300px' }}>
                                            {editExpenseId === expense._id ? (

                                                <div style={{ width: "150px" }}>

                                                    <TextField
                                                        value={editedExpenseData.toWhom}

                                                        onChange={(e) => setEditedExpenseData({
                                                            ...editedExpenseData,
                                                            toWhom: e.target.value,
                                                        })}
                                                        fullWidth // Set the fullWidth prop to true
                                                    />
                                                </div>


                                            ) : (
                                                expense.toWhom)}
                                        </TableCell>

                                       
 


                                        <TableCell style={{ width: '300px' }}>
                                            {editExpenseId === expense._id ? (
                                                <div style={{ width: "150px" }}>

                                                    <TableCell>
                                                        <Select
                                                            value={editedExpenseData.type}
                                                            onChange={(e) => setEditedExpenseData({
                                                                ...editedExpenseData,
                                                                type: e.target.value,
                                                            })}
                                                            fullWidth // Set the fullWidth prop to true
                                                        >

                                                            <MenuItem value="Food">Food</MenuItem>
                                                            <MenuItem value="Prêt Employé">Prêt Employé</MenuItem>
                                                            <MenuItem value="Transport">Transport</MenuItem>
                                                            <MenuItem value="Service">Service</MenuItem>
                                                            <MenuItem value="Loyer">Loyer</MenuItem>
                                                            <MenuItem value="Salaire">Salaire</MenuItem>
                                                            <MenuItem value="Paiment Fournisseur">Paiment Fournisseur</MenuItem>
                                                            <MenuItem value="Bien">Bien</MenuItem>
                                                            <MenuItem value="Prime">Prime</MenuItem>
                                                            <MenuItem value="Consommable">Consommable</MenuItem>
                                                            <MenuItem value="Internet">Internet</MenuItem>
                                                            <MenuItem value="Dividende">Dividende</MenuItem>
                                                            <MenuItem value="Crédit Télephone">Crédit Télephone</MenuItem>
                                                            <MenuItem value="Autres">Autres</MenuItem>
                                                        </Select>

                                                    </TableCell>



                                                </div>
                                            ) : (
                                                expense.type
                                            )}
                                        </TableCell>



                                        {/* <TableCell>{expense.item.category}</TableCell> */}
                                        <TableCell>{expense.item.itemName}</TableCell>
                                        <TableCell>{expense.item.unit}</TableCell>
                                        <TableCell>{expense.item.quantity}</TableCell>
                                        <TableCell>
                                            {expense.item && typeof expense.item.price !== 'undefined' ? (
                                                expense.item.price.toLocaleString('en-US', {
                                                    style: 'currency',
                                                    currency: 'GNF',
                                                    minimumFractionDigits: 2,
                                                })
                                            ) : (
                                                'N/A'
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            {expense.item && typeof expense.item.total !== 'undefined' ? (
                                                expense.item.total.toLocaleString('en-US', {
                                                    style: 'currency',
                                                    currency: 'GNF',
                                                    minimumFractionDigits: 2,
                                                })
                                            ) : (
                                                'N/A'
                                            )}
                                        </TableCell>

                                        <TableCell style={{ width: '300px' }}>
                                            {editExpenseId === expense._id ? (
                                                <div style={{ width: "150px" }}>

                                                    <TableCell>
                                                        <Select
                                                            value={editedExpenseData.budgetName}
                                                            onChange={(e) => setEditedExpenseData({
                                                                ...editedExpenseData,
                                                                budgetName: e.target.value,
                                                            })}
                                                            fullWidth // Set the fullWidth prop to true
                                                        >

                                                            <MenuItem value="Compte depense">Compte depense </MenuItem>
                                                            <MenuItem value="Compte Revenu">Compte Revenu  </MenuItem>
                                                        </Select>

                                                    </TableCell>



                                                </div>
                                            ) : (
                                                expense.budgetName
                                            )}
                                        </TableCell>

                                        <TableCell style={{ width: '300px' }}>
                                            {editExpenseId === expense._id ? (
                                                <div style={{ width: "150px" }}>

                                                    <TableCell>
                                                        <Select
                                                            value={editedExpenseData.modeDePaiement}
                                                            onChange={(e) => setEditedExpenseData({
                                                                ...editedExpenseData,
                                                                modeDePaiement: e.target.value,
                                                            })}
                                                            fullWidth // Set the fullWidth prop to true
                                                        >

                                                            <MenuItem value="Cash">Cash </MenuItem>
                                                            <MenuItem value="Orange Money">Orange Money  </MenuItem>
                                                        </Select>

                                                    </TableCell>



                                                </div>
                                            ) : (
                                                expense.modeDePaiement
                                            )}
                                        </TableCell>

                                        <TableCell style={{ width: '300px' }}>
                                            {editExpenseId === expense._id ? (
                                                <div style={{ width: "150px" }}>

                                                    <TextField

                                                        value={editedExpenseData.fraisDeRetrait}
                                                        onChange={(e) => setEditedExpenseData({
                                                            ...editedExpenseData,
                                                            fraisDeRetrait: e.target.value,
                                                        })} />






                                                </div>
                                            ) : (
                                                expense.fraisDeRetrait
                                            )}
                                        </TableCell>


                                        <TableCell style={{ width: '300px' }}>
                                            {editExpenseId === expense._id ? (
                                                <div style={{ width: "150px" }}>

                                                    <TextField

                                                        value={editedExpenseData.fraisDeDepot}
                                                        onChange={(e) => setEditedExpenseData({
                                                            ...editedExpenseData,
                                                            fraisDeDepot: e.target.value,
                                                        })} />

                                                </div>
                                            ) : (
                                                    expense.fraisDeDepot
                                            )}
                                        </TableCell>

                                        <TableCell>

                                            <div
                                            style={{
                                                width: '300px'
                                            }}
                                            
                                            >

                                            { editExpenseId === expense._id ? (
                                                <div style={ { width: "150px" } }>
                                                    <Dropzone onDrop={ handleImageLinksChange } accept="image/*" multiple>
                                                        { ({ getRootProps, getInputProps }) => (
                                                            <div
                                                                { ...getRootProps() }
                                                                style={ {
                                                                    border: '1px dashed #ccc',
                                                                    padding: '20px',
                                                                    textAlign: 'center',
                                                                } }
                                                            >
                                                                <input { ...getInputProps() } />
                                                                <p>Cliquez ici pour Ajouter des images</p>
                                                                { uploadedImages.length > 0 && (
                                                                    <Stack direction="row" spacing={ 1 }>
                                                                        { uploadedImages.map((imageUrl, index) => (
                                                                            <Image
                                                                                key={ index }
                                                                                cloudName="ds1hlry5u"
                                                                                publicId={ imageUrl }
                                                                                width="100"
                                                                            />
                                                                        )) }
                                                                    </Stack>
                                                                ) }
                                                            </div>
                                                        ) }
                                                    </Dropzone>


                                                </div>
                                            ) : (
                                                    <>
                                                        { expense.imagesLinks.length !== 0 ? (
                                                            expense.imagesLinks.map((imageUrl, index) => {
                                                                // Check if imageUrl is a valid link and follows the specified format
                                                                const isValidImageUrl = typeof imageUrl === 'string' && /^https:\/\/.*\.(jpg|jpeg|png|gif)$/i.test(imageUrl);

                                                                // Display "No Image" if imageUrl is not a valid link or doesn't follow the specified format
                                                                if (!isValidImageUrl) {
                                                                    return <span key={ index }>  </span>;
                                                                }

                                                                // Render the image if imageUrl is a valid link and follows the specified format
                                                                return (
                                                                    <img
                                                                        key={ index }
                                                                        src={ imageUrl }
                                                                        alt={ `Image ${index}` }
                                                                        style={ {
                                                                            display: 'inline-block',
                                                                            maxWidth: '99px',
                                                                            maxHeight: '63px',
                                                                            marginRight: '5px',
                                                                            borderRadius: '12px',
                                                                        } }
                                                                        onClick={ () => handleImageClick(imageUrl, expense._id) }
                                                                    />
                                                                );
                                                            })
                                                        ) : (
                                                            <span> </span>
                                                        ) }
                                                    </>


                                            ) }

                                            </div>

                                        </TableCell>




                                        


                                        <TableCell>
                                            {editExpenseId === expense._id ? (
                                                <>
                                                    <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>


                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            onClick={handleUpdateExpense}
                                                            style={{ width: '100%' }}
                                                            // style={buttonStyle}
                                                            disabled={isSubmitting} // Disable button while submitting
                                                        >
                                                            {isSubmitting ? (
                                                                <CircularProgress size={24} color="inherit" /> // Show CircularProgress while submitting
                                                            ) : (
                                                                'Save'
                                                            )}
                                                        </Button>


                                                        <div style={{ margin: '0 8px' }}></div> {/* Add some margin */}
                                                        <Button
                                                            variant="contained"
                                                            // color="default"
                                                            style={{ backgroundColor: '#8B0000', color: 'white' }} // Apply dark red background (#8B0000)
                                                            onClick={() => {
                                                                setEditExpenseId(null);
                                                                setEditedExpenseData({});
                                                            } }
                                                        >
                                                            Cancel
                                                        </Button>
                                                    </div>
                                                </>


                                            ) : (
                                                <Button
                                                    variant="contained"
                                                    color="secondary"
                                                    onClick={() => handleEditExpense(expense._id)}
                                                >
                                                    Edit
                                                </Button>
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                onClick={() => handleDeleteExpense(expense._id)}
                                            >
                                                Delete
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                );
                            })
                        ) : (
                            <TableRow>
                                <TableCell colSpan={7}>No expenses found.</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            {selectedImage && (
                <ImageModalExpenses
                    style={{
                        zIndex: 9999,

                    }}
                    expenseID={ selectedRowId }
                    setUpdateTables={ setUpdateTable }

                    imageUrl={selectedImage} onClose={handleCloseModal} />
            )}
            <ToastContainer position="top-center" autoClose={5000} />
        </div>
    );
}

export default ExpenseTable;
