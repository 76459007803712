import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import axios from 'axios';
import { Container, Typography, TextField, Button, CircularProgress, Link } from '@mui/material';

function Rgister() {
    const [tel, setTel] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [secretCode, setSecretCode] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);


    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Check if any of the fields are empty
        if (!name || !tel || !secretCode) {
            toast.error('Please fill in all fields.'); // Display an error message
            return;
        }



        setIsSubmitting(true)


        // Continue with form submission logic if all fields are filled

        try {
            // Make an API request API with tel and secretCode
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/users`, {
                tel,
                secretCode,
                name, email
            });

            const token = response.data.userToken;
            const userRole = response.data.userRole;

            // Store the token in localStorage
            localStorage.setItem('token', token);
            localStorage.setItem('userRole', userRole);
            localStorage.setItem('userTel', tel);
            // Redirect to the dashboard
            toast.success('Register Successfully', {
                position: 'top-center',
                autoClose: 5000, // 5 seconds
            });

            // Delay the navigation by 3 seconds
            setTimeout(() => {

                setIsSubmitting(false);
                navigate('/login', { replace: true });

            }, 4000);

        } catch (error) {
            toast.error('Register Failed try again', {
                position: 'top-center',
                autoClose: 5000, // 5 seconds
            });
            setIsSubmitting(false);


            console.error('Register  failed:', error);
        }
    };

    const containerStyle = {
        marginTop: '2rem',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    };

    const formStyle = {
        width: '100%',
        marginTop: '2rem',
    };

    const buttonStyle = {
        marginTop: '2rem',
    };

    const goToLoginPage = () => {
        navigate('/login');
    }

    return (
        <Container component="main" maxWidth="xs" style={containerStyle}>
            <Typography variant="h5">Registration Page</Typography>
            ........................................

            <Link to="/login" onClick={goToLoginPage} style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                or Login
            </Link>
            ........................................

            <form onSubmit={handleSubmit} style={formStyle}>
                <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    label="name"
                    type="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />

                <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    label="tel"
                    type="tel"
                    value={tel}
                    onChange={(e) => setTel(e.target.value)}
                />

                <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    label="Email"
                    type="email"
                    value={ email }
                    onChange={ (e) => setEmail(e.target.value) }
                />
                <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    label="password"
                    type="password"
                    value={secretCode}
                    onChange={(e) => setSecretCode(e.target.value)}
                />
            

                <Button
                    variant="contained"
                    type="submit"

                    color="primary"
                    // onClick={handleUploadImages}
                    style={{ marginTop: '20px', position: 'relative' }} // Position relative for CircularProgress
                    disabled={isSubmitting} // Disable button while submitting
                >
                    {isSubmitting ? (
                        <CircularProgress size={24} color="inherit" /> // Show CircularProgress while submitting
                    ) : (
                        'Register'
                    )}
                </Button>
                <ToastContainer position="top-center" autoClose={5000} />

            </form>
        </Container>
    );
}

export default Rgister;
