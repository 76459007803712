import React, { useState } from 'react';

import Dropzone from 'react-dropzone';
import { Image, Video } from 'cloudinary-react';
import { useNavigate } from 'react-router-dom';

import {
    Container,
    TextField,
    Button,
    Grid,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Box, Stack, CircularProgress
} from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



function UploadModal({ onClose, goodsID, modalGoodsText }) {
    const [selectedFile, setSelectedFile] = useState(null);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
    };

    const handleUpload = () => {
        // You can implement your image upload logic here
        // For simplicity, we'll just log the selected file here
        console.log('Selected File:', selectedFile);

        // Close the modal
        onClose();
    };

    const modalStyle = {
        position: 'fixed',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: '1000',
    };

    const contentStyle = {
        backgroundColor: 'white',
        maxWidth: '90%', // Adjust the maximum width as needed
        padding: '20px',
        borderRadius: '8px',
        position: 'relative',
        textAlign: 'center',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        overflow: 'hidden',
        maxHeight: '90vh', // Limit the maximum height for small screens
    };

    const closeButtonStyle = {
        position: 'absolute',
        top: '10px',
        right: '10px',
        cursor: 'pointer',
        fontSize: '20px',
        color: '#888',
    };

    const initialFormData = {
        [modalGoodsText]: [], // Use the modalGoodsText prop as the key
    };
    const [formData, setFormData] = useState(initialFormData);

    const navigate = useNavigate();
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [uploadedImagesgoodsImagesLinks, setUploadedImagesgoodsImagesLinks] = useState([]);

    const _0x2cd4 = ["\x64\x73\x31\x68\x6C\x72\x79\x35\x75"];
    const cldNme = _0x2cd4[0];
    const _0xe8a5 = ["\x74\x73\x79\x76\x78\x31\x68\x72", "\x74\x73\x79\x76\x78\x31\x68\x72"];
    // eslint-disable-next-line camelcase
    const presetingCldVid = _0xe8a5[0];


    const handleImageUploadgoodsImagesLinks = async (files) => {
        try {
            setIsSubmitting(true);

            const uploadPromises = files.map((file) => {
                const formData = new FormData();
                formData.append('file', file);
                formData.append('upload_preset', 'tsyvx1hr'); // Replace with your Cloudinary upload preset

                return fetch(`https://api.cloudinary.com/v1_1/${cldNme}/image/upload`, {
                    method: 'POST',
                    body: formData,
                })
                    .then((response) => response.json())
                    .then((data) => data.secure_url);

            });

            const imageUrls = await Promise.all(uploadPromises);

            if (imageUrls.length > 0) {
                setIsSubmitting(false);

            }
            // setIsSubmitting(false);

            setUploadedImagesgoodsImagesLinks(imageUrls);
            // setImageLinks(imageUrls); // Save the uploaded image links to imageLinks state
            setFormData({
                ...formData,
                [modalGoodsText]: imageUrls,
            });
            // toast.success('Image(s) uploaded successfully', {
            //     position: 'Bottom-center',
            //     autoClose: 2000,
            //     style: {
            //         top: '50%', // Adjust the top position to your desired level
            //         // transform: 'translate(-50%, -50%)',
            //         zIndex: 9999,
            //     },
            // });


        } catch (error) {
            console.error('Error uploading images:', error);
        }
    };

    const handleImageLinksChangegoodsImagesLinks = (acceptedFiles) => {
        handleImageUploadgoodsImagesLinks(acceptedFiles);
    };

    // const handleUploadImages = () => {
    //     console.log(formData);
    //     console.log(goodsID);
    //     console.log(modalGoodsText);
        

    // }
    

    const handleUploadImages = async () => {
        console.log(formData);

            console.log(formData);
        console.log(JSON.stringify(formData));
            console.log(modalGoodsText);

        try {
            setIsSubmitting(true);
            const authToken = localStorage.getItem('token');
            const authorizationHeader = `Bearer ${authToken}`;

            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/goods-tracking/updateBySender/${goodsID}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: authorizationHeader,
                },
                body: JSON.stringify(formData), // JSON.stringify the body
            });


            const data = await response.json();

            if (response.ok) {
                // Handle success
                toast.success('Image Upladed Successfully', {
                    position: 'Bottom-center',
                    autoClose: 5000, // 5 seconds
                });
                console.log('Expense submitted successfully:', data);
                setFormData(initialFormData);


                // Delay the navigation by 3 seconds
                setTimeout(() => {
                    setIsSubmitting(false);

                    onClose();

                    window.location.reload();

                }, 3000);

                // Handle success, e.g., show a success message or redirect
                console.log('Form submitted successfully');
            } else {
                toast.error('Failed to Submit', {
                    position: 'Bottom-center',
                    autoClose: 5000, // 5 seconds
                });
                console.error('Failed to POST NEW GOODS');
                setIsSubmitting(false);
            }

        } catch (error) {
            // Handle error, e.g., show an error message
            toast.error(`${error}`, {
                position: 'Bottom-center',
                autoClose: 5000, // 5 seconds
            });
            console.error('Error submitting form:', error);
            setIsSubmitting(false);
        }
    };



   

    return (
        <div style={modalStyle}>
            <div style={contentStyle}>
                <span style={closeButtonStyle} onClick={onClose}>&times;</span>
                <h2>Upload Image</h2>

                <Dropzone onDrop={handleImageUploadgoodsImagesLinks} accept="image/*" multiple>
                    {({ getRootProps, getInputProps }) => (
                        <div
                            {...getRootProps()}
                            style={{
                                border: '1px dashed #ccc',
                                padding: '20px',
                                textAlign: 'center',
                            }}
                        >
                            <input {...getInputProps()} />
                            <p>Click Here , for Photo client Receipt  </p>
                            {uploadedImagesgoodsImagesLinks.length > 0 && (
                                <Stack direction="row" spacing={1}>
                                    {uploadedImagesgoodsImagesLinks.map((imageUrl, index) => (
                                        <Image
                                            key={index}
                                            cloudName="ds1hlry5u"
                                            publicId={imageUrl}
                                            width="100"
                                        />
                                    ))}
                                </Stack>
                            )}
                        </div>
                    )}
                </Dropzone>
                {/* <input type="file" onChange={handleFileChange} />
                <button onClick={handleUpload}>Upload</button> */}
                {/* <Button
                    variant="contained"
                    onClick={handleUploadImages}
                    style={{ backgroundColor: 'primary', color: 'white', flex: '1', minWidth: '150px', marginTop: '16px' }}

                >
                    Upload
                </Button> */}

                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleUploadImages}
                    style={{ marginTop: '20px', position: 'relative' }} // Position relative for CircularProgress
                    disabled={isSubmitting} // Disable button while submitting
                >
                    {isSubmitting ? (
                        <CircularProgress size={24} color="inherit" /> // Show CircularProgress while submitting
                    ) : (
                        'Submit'
                    )}
                </Button>

                <ToastContainer position="Bottom-center" autoClose={5000} />

            </div>
        </div>
    );
}

export default UploadModal;
