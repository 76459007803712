// import React, { useEffect } from 'react';

import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { ToastContainer } from 'react-toastify';
import { Provider } from 'react-redux';


// routes
import Router from './Routes';
 
export default function App() {
  return (
      <HelmetProvider >
      <BrowserRouter>
          <Router />
      </BrowserRouter>
    </HelmetProvider>
  );
}
