import React, { useState } from 'react';
import Button from '@mui/material/Button';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import {
    Link,
    Stack,
    IconButton,
    InputAdornment,
    TextField,
    Checkbox,
    Select,
    MenuItem, FormControl,
    InputLabel, CircularProgress, Image as ImageDisplay,

} from '@mui/material';
import Dropzone from 'react-dropzone';
import { Image, Video } from 'cloudinary-react';






function AvatarLinkUpdate() {
    const [secretCode, setsecretCode] = useState('');
    const [avatarLink, setAvatarLink] = useState('');

    const [pinCode, setpinCode] = useState('');
    const [confirmpinCode, setConfirmpinCode] = useState('');
    const { t } = useTranslation();
    const [uploadedImages, setUploadedImages] = useState([]);

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isImageUploaded, setIsImageUploaded] = useState(false);

  

    const navigate = useNavigate(); // useNavigate hook


    const [imageLinks, setImageLinks] = useState(['']);


    const cldNme = `${process.env.REACT_APP_CLOUDINARY_CLDNME_URL}`;

    // eslint-disable-next-line camelcase
    const handleImageUpload = async (files) => {
        try {
            setIsSubmitting(true);

            const file = files[0]; // Assuming you only handle a single image here
            const formData = new FormData();
            formData.append('file', file);
            formData.append('upload_preset', `${process.env.REACT_APP_CLOUDINARY_PRESET}`); // Replace with your Cloudinary upload preset

            const response = await fetch(`https://api.cloudinary.com/v1_1/${cldNme}/image/upload`, {
                method: 'POST',
                body: formData,
            });

            if (response.ok) {
                setIsSubmitting(false);
                setIsImageUploaded(true);

                const data = await response.json();
                const imageUrl = data.secure_url;

                // Update the imageLinks state with the single URL
                setImageLinks(imageUrl);

                toast.success('Image uploaded successfully', {
                    position: 'top-center',
                    autoClose: 5000, // 5 seconds
                });
            } else {
                throw new Error('Image upload failed');
            }
        } catch (error) {
            setIsSubmitting(false);


            console.error('Error uploading image:', error);
            toast.error('error, Upload one Image Only', {
                position: 'top-center',
                autoClose: 5000, // 5 seconds
            });
        }
    };


    const handleImageLinksChange = (acceptedFiles) => {
        handleImageUpload(acceptedFiles);
    };






    const handlepinCodeChange = (event) => {
        setpinCode(event.target.value);
    };


    const handleSubmit = async (event) => {
        const authToken = localStorage.getItem('token');
        setIsSubmitting(true);

        event.preventDefault();

        try {

            console.log(imageLinks);
            const response = await axios.put(
                `${process.env.REACT_APP_BASE_URL}/user/updateOwnImage`,

                {
                    imageProfile: imageLinks,
                },
                {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                    },
                }
            );




            // Check the response status and show a success message
            if (response.status === 200) {
                toast.success('Profile Image successfully, Login again', {
                    position: 'top-center',
                    autoClose: 5000, // 5 seconds
                });
                setIsImageUploaded(false);




                localStorage.setItem('user_picture', imageLinks);

                setpinCode('')
                setImageLinks([''])

                // Delay the navigation by 3 seconds
                setTimeout(() => {
                    localStorage.removeItem('token');
                    localStorage.removeItem('userRole');
                    localStorage.removeItem('tel');
                    localStorage.removeItem('imageProfile');                    

                    navigate('/login'); // Use the navigate function to redirect


                }, 3000);

            } else {
                toast.error('Failed to update avatar link', {
                    position: 'top-center',
                    autoClose: 5000, // 5 seconds
                });
            }
        } catch (error) {
            console.error('Error updating avatar link:', error);
            toast.error('An error occurred while updating avatar link', {
                position: 'top-center',
                autoClose: 5000, // 5 seconds
            });
        }
    };

    const blinkKeyframes = `
  @keyframes blink {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

    const changeColorKeyframes = `
  @keyframes changeColor {
    0% {
      color: #3498db;
    }
    50% {
      color: #e74c3c; /* Change to another color */
    }
    100% {
      color: #3498db;
    }
  }
`;


    return (
        <form onSubmit={handleSubmit}>
            {/* Add margin to the TextField components */}



            {isImageUploaded ? (
                <>

                    <div>
                        <style>{blinkKeyframes}</style>
                        <style>{changeColorKeyframes}</style>
                        <div
                            style={{
                                textAlign: 'center',
                                fontSize: '20px',
                                fontWeight: 'bold',
                                margin: '20px 0',
                                animation: `
            blink 1s infinite,
            changeColor 2s infinite`,
                            }}
                        >
                            Image is Ready! CLick Change 
                        </div>
                    </div>



                </>
            ) : (
                <>
                    <Dropzone onDrop={handleImageUpload} accept="image/*" maxFiles={1}>
                        {({ getRootProps, getInputProps }) => (
                            <div
                                {...getRootProps()}
                                style={{
                                    border: '1px dashed #ccc',
                                    padding: '20px',
                                    textAlign: 'center',
                                }}
                            >
                                <input {...getInputProps()} />
                                <p>Drag 'n' drop an image here, or click to select an image</p>
                            </div>
                        )}
                    </Dropzone>

                </>
            )}



            <Button
                type="submit"
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                style={{ marginTop: '20px', position: 'relative' }} // Position relative for CircularProgress
                disabled={isSubmitting} // Disable button while submitting
            >
                {isSubmitting ? (
                    <CircularProgress size={24} color="inherit" /> // Show CircularProgress while submitting
                ) : (
                    <>
                        Change Photo

                    </>

                )}
            </Button>
            <ToastContainer position="top-center" autoClose={5000} />

        </form>
    );
}

export default AvatarLinkUpdate;
