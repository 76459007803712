import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { TextField, Button, Grid,
    Container, FormControl,
InputLabel,
Select,
    MenuItem, CircularProgress,
     TextareaAutosize, 
     Typography
    } from '@mui/material';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';





const NewTaskForm = () => {
    const [formData, setFormData] = useState({
        deadline: '',
        closingDate: '',
        taskName: '',
        actions: '',
        progress: '',
        status: '',
        supplier: '',
        responsible: '',
        quantity: 1,
        unitPrice: 0,
        totalAmount: 0,
        remark: '',
        niveauDurgence: ''
    });


    const [isSubmitting, setIsSubmitting] = useState(false);


    useEffect(() => {
        // Calculate totalAmount whenever quantity or unitPrice changes
        const { quantity, unitPrice } = formData;
        const newTotalAmount = quantity * unitPrice;
        setFormData({ ...formData, totalAmount: newTotalAmount });
    }, [formData.quantity, formData.unitPrice]);



    const authToken = localStorage.getItem('token');

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };



    const handleSubmit = async (e) => {
        setIsSubmitting(true)
        e.preventDefault();
        console.log(`formData ${formData}`);

        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/task/new`, formData, {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            });

            // Handle success or display a message to the user

            toast.success('Task submitted successfully', {
                position: 'top-center',
                autoClose: 5000, // 5 seconds
            });

            setIsSubmitting(false)



            setFormData({
                deadline: '',
                closingDate: '',
                taskName: '',
                actions: '',
                progress: '',
                status: 'processing',
                supplier: '',
                responsible: '',
                quantity: 0,
                unitPrice: 0,
                totalAmount: 0,
                remark: '',
                niveauDurgence: ''
            });


        } catch (error) {
            // Handle the error or display an error message
            console.error('Error submitting task:', error);

            toast.error('Error, Try again', {
                position: 'top-center',
                autoClose: 5000, // 5 seconds
            });

            setIsSubmitting(false)

        }
    };

    return (
        <Container maxWidth="sm">
            <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            label="Deadline"
                            type="date"
                            name="deadline"
                            value={formData.deadline}
                            onChange={handleInputChange}
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Closing Date"
                            type="date"
                            name="closingDate"
                            value={formData.closingDate}
                            onChange={handleInputChange}
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            label="Task Name"
                            type="text"
                            name="taskName"
                            value={formData.taskName}
                            onChange={handleInputChange}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextareaAutosize
                            label="Actions"
                            name="actions"
                            value={formData.actions}
                            onChange={handleInputChange}
                            minRows={3}
                            placeholder="Actions..."
                            style={{ width: '100%', whiteSpace: 'pre-line' }}
                        />
                    </Grid>

 

                    <Grid item xs={12}>
                        <TextareaAutosize
                            label="Progress"
                            name="progress"
                            value={formData.progress}
                            onChange={handleInputChange}
                            minRows={3}
                            placeholder="progress..."
                            style={{ width: '100%', whiteSpace: 'pre-line' }}
                        />
                    </Grid>

                    <Grid item xs={12}>
                   

                        <FormControl style={{ marginBottom: '16px', minWidth: '100%' }}>
                            <InputLabel> Status * </InputLabel>
                            <Select
                                label="status"
                                name="status"
                                value={formData.status}
                                onChange={handleInputChange}
                                fullWidth

                            >
                                <MenuItem value="pending">Pending</MenuItem>
                                <MenuItem value="processing">Processing</MenuItem>
                                <MenuItem value="completed">Completed</MenuItem>
                                <MenuItem value="reported">Reported</MenuItem>
                                <MenuItem value="blocked">Blocked</MenuItem>
                                <MenuItem value="cancelled">Cancelled</MenuItem>

                                {/* Add more options as needed */}
                            </Select>
                        </FormControl>




                    </Grid>

                    <Grid item xs={12}>


                        <FormControl style={{ marginBottom: '16px', minWidth: '100%' }}>
                            <InputLabel> niveau D'Urgence * </InputLabel>
                            <Select
                                label="niveauDurgence"
                                name="niveauDurgence"
                                value={formData.niveauDurgence}
                                onChange={handleInputChange}
                                fullWidth

                            >
                                <MenuItem value="Urgent">Urgent</MenuItem>
                                <MenuItem value="Moin Urgent">Moin Urgent</MenuItem>
                                <MenuItem value="Pas Urgent">Pas Urgent</MenuItem>
                            </Select>
                        </FormControl>




                    </Grid>

                    

                   


                    <Grid item xs={12}>
                        <TextField
                            label="Supplier"
                            type="text"
                            name="supplier"
                            value={formData.supplier}
                            onChange={handleInputChange}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Responsible"
                            type="text"
                            name="responsible"
                            value={formData.responsible}
                            onChange={handleInputChange}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Quantity"
                            type="number"
                            name="quantity"
                            value={formData.quantity}
                            onChange={handleInputChange}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Unit Price"
                            type="number"
                            name="unitPrice"
                            value={formData.unitPrice}
                            onChange={handleInputChange}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        {/* <TextField
                            label="Total Amount"
                            type="number"
                            name="totalAmount"
                            value={formData.totalAmount}
                            onChange={handleInputChange}
                            fullWidth
                        /> */}
                        <Typography >
                          Total :   {formData.totalAmount}
                        </Typography>
                    
                    </Grid>
                    <Grid item xs={12}>
                        <TextareaAutosize
                            label="Remark"
                            name="remark"
                            value={formData.remark}
                            onChange={handleInputChange}
                            minRows={3}
                            placeholder="Remark..."
                            style={{ width: '100%', whiteSpace: 'pre-line' }}
                        />
                    </Grid>

            

                </Grid>


                {/* <Button variant="contained" color="primary" type="submit">
                    Submit Task
                </Button> */}

                <Button
                    variant="contained" color="primary" type="submit"
                    style={{ marginTop: '20px', position: 'relative' }} // Position relative for CircularProgress
                    disabled={isSubmitting} // Disable button while submitting
                >
                    {isSubmitting ? (
                        <CircularProgress size={24} color="inherit" /> // Show CircularProgress while submitting
                    ) : (
                        'Submit'
                    )}
                </Button>
            </form>

            <ToastContainer position="top-center" autoClose={5000} />

        </Container>
    );
};

export default NewTaskForm;
