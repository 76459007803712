import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    Select,
    MenuItem,
    TextField,
    InputLabel,
    FormControl,
    IconButton,
    List, Typography,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function UserTable() {
    const [userData, setUserData] = useState([]);
    const [editMode, setEditMode] = useState({});
    const [updatedUsers, setUpdatedUsers] = useState({});
    const [userSpecialRole, setUserSpecialRole] = useState({}); // State for edit mode of each row

    const [filters, setFilters] = useState({
        tel: '',
        role: '',
        type: '',
    });

    const accessListOptions = [
        'allAccess',
        'createExpenses',
        'getAllBudget',
        'createExpense',
        'getAllExpenses',
        'UpdateExpensesById',
        'deleteExpensesById',
        'createGoodsTracking',
        'getAllGoods',
        'getAllGoodsForUser',
        'getAllGoodsForSender',
        'updateGoodsTrackingBySender',
        'deleteGoodsTracking',
        'createTickets',
        'getAllTicketByAdmin',
        'getAllTicketByUser',
        'createUser',
        'adminUpdateUserById',
        'removeAccessListItem',
        'getAllUsers',
        'deleteUserById',
        'getUserById',
        'updateGoodsTrackingByAdmin',
        'getAllClients'
    ];


    useEffect(() => {
        setUserSpecialRole(localStorage.getItem('userSpecialRole'))

        const fetchData = async () => {
            try {
                const authToken = localStorage.getItem('token');
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/allusers/info`, {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                    },
                });
                setUserData(response.data.users);
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };

        fetchData();
    }, []);

    const handleEditClick = (userId) => {
        setEditMode({ ...editMode, [userId]: true });
    };

    const handleCancelEdit = (userId) => {
        setEditMode({ ...editMode, [userId]: false });
        setUpdatedUsers({ ...updatedUsers, [userId]: { ...userData.find((user) => user._id === userId) } });
    };

    const handleSaveEdit = async (userId) => {
        try {
            const authToken = localStorage.getItem('token');
            const updatedData = updatedUsers[userId];

            console.log(updatedData);

            await axios.put(`${process.env.REACT_APP_BASE_URL}/user/update/${userId}`, updatedData, {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            });

            setEditMode({ ...editMode, [userId]: false });

            // Update the user data after saving the edit
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/allusers/info`, {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            });
            setUserData(response.data.users);
        } catch (error) {
            toast.error(`${error.response.data.error}`, {
                position: 'top-center',
                autoClose: 5000,
            });
            console.log(error);
            console.error('Error updating user data:', error);
        }
    };

    const handleFieldChange = (userId, field, value) => {
        setUpdatedUsers({
            ...updatedUsers,
            [userId]: {
                ...updatedUsers[userId],
                [field]: value,
            },
        });
    };

    const handleFilterChange = (field, value) => {
        setFilters({ ...filters, [field]: value });
    };


    const filteredUsers = userData.filter((user) => {
        const telMatch = !filters.tel || user.tel.toLowerCase().includes(filters.tel.toLowerCase());
        const roleMatch = !filters.role || user.role.toLowerCase() === filters.role.toLowerCase();
        const typeMatch = !filters.type || user.type.toLowerCase() === filters.type.toLowerCase();

        return telMatch && roleMatch && typeMatch;
    });
  
    
    
  

    const handleAddAccessItem = (userId, newItem) => {
        setUpdatedUsers((prevUpdatedUsers) => {
            const updatedAccessList = [...(prevUpdatedUsers[userId]?.accessList || []), newItem];
            return {
                ...prevUpdatedUsers,
                [userId]: {
                    ...prevUpdatedUsers[userId],
                    accessList: updatedAccessList,
                },
            };
        });
    };
    

    const accessListForm = (userId) => {
        const user = userData.find((user) => user._id === userId);


      

        return (
            <div>
                <List dense>
                    {user?.accessList?.map((access, index) => (
                        <ListItem key={index}>
                            <ListItemText primary={access} />  
                                               
                        </ListItem>
                    ))}
                    {updatedUsers[userId]?.accessList?.map((access, index) => (
                        <ListItem key={index}>
                            <ListItemText primary={access} />  
                                                 
                        </ListItem>
                    ))}
                </List>
                <FormControl>
                    <InputLabel htmlFor={`access-list-${userId}`}>Add Access Item</InputLabel>
                    <Select
                        id={`access-list-${userId}`}
                        value=""
                        onChange={(e) => handleAddAccessItem(userId, e.target.value)}
                    >
                        <MenuItem value="" disabled>
                            Select an item
                        </MenuItem>
                        {accessListOptions.map((option) => (
                            <MenuItem key={option} value={option}>
                                {option}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
        );
    };



 
    const isEditMode = (userId) => editMode[userId];
    const handleDeleteUser = async (userId) => {
        // Display a confirmation dialog before proceeding
        const confirmDelete = window.confirm('Are you sure you want to delete this user?');

        if (confirmDelete) {
            try {
                const authToken = localStorage.getItem('token');

                await axios.delete(`${process.env.REACT_APP_BASE_URL}/user/delete/${userId}`, {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                    },
                });

                // Update the user data after deleting the user
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/allusers/info`, {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                    },
                });
                setUserData(response.data.users);
            } catch (error) {
                console.error('Error deleting user:', error);
            }
        }
    };



    return (

        <div>
            <div>
                <Typography variant="h6" style={{ fontSize: '2rem', fontWeight: 'bold' }}>

                    Users 👥
                </Typography>


                 <FormControl style={{ width: '200px' }} >
          {/* <InputLabel htmlFor="tel-filter">Filter by Telephone</InputLabel> */}
          <TextField
          label='User Telephone'
            id="tel-filter"
            value={filters.tel}
            onChange={(e) => handleFilterChange('tel', e.target.value)}
          />
        </FormControl>
        <FormControl style={{ width: '200px' }}>
          <InputLabel
          style={{
            backgroundColor: 'white',
          }}
          htmlFor="role-filter">Filter by Role</InputLabel>
          <Select
          
            id="role-filter"
            value={filters.role}
            onChange={(e) => handleFilterChange('role', e.target.value)}
          >
                        <MenuItem value="">All Roles</MenuItem>
                        <MenuItem value="admin">Admin</MenuItem>
                        <MenuItem value="client">Client</MenuItem>
                        <MenuItem value="hr">HR</MenuItem>
                        <MenuItem value="commercial">Commercial</MenuItem>
                        <MenuItem value="IT">IT</MenuItem>
                        <MenuItem value="other">Other</MenuItem>
                        <MenuItem value="sender">Sender</MenuItem>
          </Select>
        </FormControl>
        <FormControl style={{  width: '200px' }}>
          <InputLabel
                        style={{
                            backgroundColor: 'white',
                        }}
          htmlFor="type-filter">Filter by Type</InputLabel>
          <Select
            id="type-filter"
            value={filters.type}
            onChange={(e) => handleFilterChange('type', e.target.value)}
          >
                        <MenuItem value="">All Types</MenuItem>
                        <MenuItem value="employee">Employee</MenuItem>
                        <MenuItem value="client">Client</MenuItem>
                        <MenuItem value="partner">Partner</MenuItem>
          </Select>
        </FormControl>
            </div>
        <TableContainer
        style={{
            height: '750px',
            marginTop: '20px'
        }}
        component={Paper}>
            <Table>
                    <TableHead
                        style={{
                            backgroundColor: '#78AEF9',
                            position: 'sticky',
                            top: 0,
                            zIndex: 1000,
                            fontWeight: 'bold', // Apply bold font
                        }}
                    >
                    <TableRow>
                            <TableCell 
                             style={{
                fontSize: '0.96rem',
                fontFamily: 'monospace',
                fontWeight: 'bold',
            }}
            >Photo</TableCell>
                            <TableCell 
                             style={{
                fontSize: '0.96rem',
                fontFamily: 'monospace',
                fontWeight: 'bold',
            }}
            >Name</TableCell>
                        <TableCell 
                         style={{
                fontSize: '0.96rem',
                fontFamily: 'monospace',
                fontWeight: 'bold',
            }}
            >Role</TableCell>
                        <TableCell 
                         style={{
                fontSize: '0.96rem',
                fontFamily: 'monospace',
                fontWeight: 'bold',
            }}
            >Type</TableCell>
                        <TableCell 
                         style={{
                fontSize: '0.96rem',
                fontFamily: 'monospace',
                fontWeight: 'bold',
            }}
            >Status</TableCell>
                        <TableCell 
                         style={{
                fontSize: '0.96rem',
                fontFamily: 'monospace',
                fontWeight: 'bold',
            }}
            >Employee ID</TableCell>
                        <TableCell 
                         style={{
                fontSize: '0.96rem',
                fontFamily: 'monospace',
                fontWeight: 'bold',
            }}
            >Email</TableCell>
                            <TableCell
                                style={ {
                                    fontSize: '0.96rem',
                                    fontFamily: 'monospace',
                                    fontWeight: 'bold',
                                } }
            >Telephone</TableCell>
                        <TableCell 
                         style={{
                fontSize: '0.96rem',
                fontFamily: 'monospace',
                fontWeight: 'bold',
            }}
            >Avatar Link</TableCell>
                        <TableCell 
                         style={{
                fontSize: '0.96rem',
                fontFamily: 'monospace',
                fontWeight: 'bold',
            }}
            >Access List</TableCell>
                        <TableCell 
                         style={{
                fontSize: '0.96rem',
                fontFamily: 'monospace',
                fontWeight: 'bold',
            }}
            >Edit</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                        {filteredUsers.map((user, index) => (
                            
                        // <TableRow key={user._id}>
                                <TableRow
                                    key={user._id}
                                    style={{
                                        backgroundColor: index % 2 === 0 ? '#ADD8E6' : '#73c3e6', // Use the color codes
                                    }}
                                >
                                <TableCell>
                                    <Typography
                                        variant="h6"
                                        style={{
                                            textDecoration: 'none',
                                            color: 'white',
                                            display: 'flex',
                                            alignItems: 'center',
                                            transition: 'transform 0.2s ease-in-out',
                                        }}
                                    >
                                        <div
                                            style={{
                                                width: '52px',
                                                height: '52px',
                                                borderRadius: '50%', // Make the image round
                                                overflow: 'hidden',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                backgroundColor: '#ccc', // Default background color
                                            }}
                                        >
                                            {user.imageProfile ? (
                                                <img src={user.imageProfile} alt="Profile" style={{ width: '100%', height: '100%' }} />
                                            ) : (
                                                // Default image or placeholder
                                                    <img src='https://res.cloudinary.com/ds1hlry5u/image/upload/v1696888477/download_dwjdb4.png' alt="Default" style={{ width: '100%', height: '100%' }} />
                                            )}
                                        </div>
                                    </Typography>
                                </TableCell>

                                
                                <TableCell>{user.name}</TableCell>
                            {editMode[user._id] ? (
                                <TableCell>
                                    <Select
                                        value={updatedUsers[user._id]?.role || user.role}
                                        onChange={(e) => handleFieldChange(user._id, 'role', e.target.value)}
                                    >
                                        <MenuItem value="admin">Admin</MenuItem>
                                        <MenuItem value="client">Client</MenuItem>
                                        <MenuItem value="hr">HR</MenuItem>
                                        <MenuItem value="commercial">Commercial</MenuItem>
                                        <MenuItem value="IT">IT</MenuItem>
                                        <MenuItem value="other">Other</MenuItem>
                                        <MenuItem value="sender">Sender</MenuItem>
                                    </Select>
                                </TableCell>
                            ) : (
                                <TableCell>{user.role}</TableCell>
                            )}
                            {editMode[user._id] ? (
                                <TableCell>
                                    <Select
                                        value={updatedUsers[user._id]?.type || user.type}
                                        onChange={(e) => handleFieldChange(user._id, 'type', e.target.value)}
                                    >
                                        <MenuItem value="employee">Employee</MenuItem>
                                        <MenuItem value="client">Client</MenuItem>
                                        <MenuItem value="partner">Partner</MenuItem>
                                    </Select>
                                </TableCell>
                            ) : (
                                <TableCell>{user.type}</TableCell>
                            )}
                            <TableCell>{user.status}</TableCell>

                                { editMode[user._id] ? (
                                    <TableCell>
                                       

                                        <div
                                            style={ {
                                                width: '150px'
                                            } }
                                        >
                                            <TextField
                                                fullWidth
                                                value={ updatedUsers[user._id]?.userID || user.userID }
                                                onChange={ (e) => handleFieldChange(user._id, 'userID', e.target.value) }
                                            />
                                        </div>
                                           
                                    </TableCell>
                                ) : (
                                        <TableCell>{ user.userID }</TableCell>
                                ) }

                                { editMode[user._id] ? (
                                    <TableCell>

                                        <div
                                        style={{
                                            width: '150px'
                                        }}
                                        >


                                        <TextField
                                            fullWidth
                                            value={ updatedUsers[user._id]?.email || user.email }
                                            onChange={ (e) => handleFieldChange(user._id, 'email', e.target.value) }
                                        />
                                        </div>

                                    </TableCell>
                                ) : (
                                    <TableCell>{ user.email }</TableCell>
                                ) }


                            <TableCell>{user.tel}</TableCell>
                            <TableCell>{user.avatarLink}</TableCell>
                                <TableCell>
                                    {isEditMode(user._id) ? (
                                        accessListForm(user._id)
                                    ) : (
                                        <ul>
                                            {user.accessList.map((access, index) => (
                                                <li key={index}>{access}</li>
                                            ))}
                                        </ul>
                                    )}
                                </TableCell>
                            <TableCell>
                                {editMode[user._id] ? (
                                    <>

                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                style={{ margin: '7px', backgroundColor: '#007bff', color: 'white' }}
                                                startIcon={<SaveIcon />} // Add the Save icon
                                                onClick={() => handleSaveEdit(user._id)}
                                            >
                                                Save
                                            </Button>
                                            <Button
                                                variant="outlined"
                                                color="secondary"
                                                style={{ margin: '7px', backgroundColor: '#E2D016', color: 'white' }}
                                                startIcon={<CancelIcon />} // Add the Cancel icon
                                                onClick={() => handleCancelEdit(user._id)}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                variant="outlined"
                                                color="secondary"
                                                style={{ margin: '7px', backgroundColor: '#dc3545', color: 'white' }}
                                                startIcon={<DeleteIcon />} // Add the Delete icon
                                                onClick={() => handleDeleteUser(user._id)}
                                            >
                                                Delete
                                            </Button>

                                    </>
                                ) : (
                                    
                                      userSpecialRole === 'IT' || 'admin' && (
                                                <Button
                                                    variant="outlined"
                                                    color="primary"
                                                    onClick={() => handleEditClick(user._id)}
                                                >
                                                    Edit
                                                </Button>
                                    )
                                )}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
            <ToastContainer />

        </div>
    );
}

export default UserTable;
