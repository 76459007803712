import React, { useState } from 'react';
import {
    TextField,
    CircularProgress,
    Button,
    Container,
    Typography,
    Box,
} from '@mui/material';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ClientForm = () => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        business: '',
        activity: '',
        type: '',
        status: '',
        phoneNumber: '',
        address: '',
        dateOfFirstContact: '',
        hasUsedService: false,
        customerNeed: '',
        actionToBeTaken: '',    
        comment: '',
    });
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const validateForm = () => {
        const { firstName, lastName, address, phoneNumber } = formData;

        if (!firstName || !lastName || !address || !phoneNumber) {
            toast.error('Please fill in all required fields', {
                position: 'top-center',
                autoClose: 5000,
            });
            return false;
        }

        if ( isNaN(phoneNumber)) {
            toast.error('Number and Phone Number must be numeric values', {
                position: 'top-center',
                autoClose: 5000,
            });
            return false;
        }

        return true;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log('handleSubmit');

        if (!validateForm()) {
            return;
        }

        setIsSubmitting(true);

        // Fetch API with authentication token
        const token = localStorage.getItem('token'); // Replace with your actual auth token

        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/clients`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                // Handle success
                console.log('Client data submitted successfully!');
                toast.success('Data submitted', {
                    position: 'top-center',
                    autoClose: 5000,
                });
                setFormData({
                    firstName: '',
                    lastName: '',
                    business: '',
                    activity: '',
                    type: '',
                    status: '',
                    phoneNumber: '',
                    address: '',
                    dateOfFirstContact: '',
                    hasUsedService: false,
                    customerNeed: '',
                    actionToBeTaken: '',
                    comment: '',
                });
            } else {
                toast.error('Error submitting client data', {
                    position: 'top-center',
                    autoClose: 5000,
                });
                // Handle other error cases if needed
                console.error('Error submitting client data');
            }
        } catch (error) {
            console.log(error);
            toast.error('Number Exist', {
                position: 'top-center',
                autoClose: 5000,
            });
            console.error('Unexpected error:', error);
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <Container>
            <Typography variant="h4" gutterBottom>
                Client Form
            </Typography>
            <form onSubmit={handleSubmit}>
                <Box>
                    <TextField
                        label="First Name"
                        variant="outlined"
                        name="firstName"
                        value={formData.firstName}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                    />
                </Box>
                <Box>
                    <TextField
                        label="Last Name"
                        variant="outlined"
                        name="lastName"
                        value={formData.lastName}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                    />
                </Box>
             
                <Box>
                    <TextField
                        label="Business"
                        variant="outlined"
                        name="business"
                        value={formData.business}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                    />
                </Box>
               
                <Box>
                    <TextField
                        label="Activity"
                        variant="outlined"
                        name="activity"
                        value={formData.activity}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                    />
                </Box>

                <Box>
                    <TextField
                        label="type"
                        variant="outlined"
                        name="type"
                        value={formData.type}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                    />
                </Box>


                <Box>
                    <TextField
                        label="status"
                        variant="outlined"
                        name="status"
                        value={formData.status}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                    />
                </Box>
                <Box>
                    <TextField
                        label="Phone Number"
                        variant="outlined"
                        name="phoneNumber"
                        value={formData.phoneNumber}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                    />
                </Box>

                <Box>
                    <TextField
                        label="Address"
                        variant="outlined"
                        name="address"
                        value={formData.address}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                    />
                </Box>
                <Box>
                    <TextField
                        label="Date of First Contact"
                        variant="outlined"
                        name="dateOfFirstContact"
                        value={formData.dateOfFirstContact}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        type='date'
                        InputLabelProps={{ shrink: true }}

                    />
                </Box>
                <Box>
                    <TextField
                        label="Customer Need"
                        variant="outlined"
                        name="customerNeed"
                        value={formData.customerNeed}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                    />
                </Box>
                <Box>
                    <TextField
                        label="Action to be Taken"
                        variant="outlined"
                        name="actionToBeTaken"
                        value={formData.actionToBeTaken}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                    />
                </Box>
               
                <Box>
                    <TextField
                        label="Comment"
                        variant="outlined"
                        name="comment"
                        value={formData.comment}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                    />
                </Box>
                <Box mt={2}>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting} // Disable button while submitting
                    >
                        {isSubmitting ? (
                            <CircularProgress size={24} color="inherit" /> // Show CircularProgress while submitting
                        ) : (
                            'Submit'
                        )}
                    </Button>
                </Box>
            </form>
            <ToastContainer position="top-center" />
        </Container>
    );
};

export default ClientForm;
