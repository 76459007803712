import React, { useEffect, useRef } from 'react';
import * as echarts from 'echarts';

function PieChart() {
    const chartRef = useRef(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch data from the API
                const authToken = localStorage.getItem('token');
                const response = await fetch(`${process.env.REACT_APP_BASE_URL}/allusers/info`, {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                    },
                });
                const data = await response.json();

                // Process the data to count user types
                const typeCounts = {};
                data.users.forEach((user) => {
                    const type = user.type;
                    if (type) {
                        if (typeCounts[type]) {
                            typeCounts[type] += 1;
                        } else {
                            typeCounts[type] = 1;
                        }
                    }
                });

                // Prepare data for the pie chart
                const pieData = Object.keys(typeCounts).map((type) => ({
                    value: typeCounts[type],
                    name: type,
                }));

                // Initialize ECharts and create the chart
                const myChart = echarts.init(chartRef.current);
                const option = {
                   
                    tooltip: {
                        trigger: 'item',
                        formatter: '{a} <br/>{b} : {c} ({d}%)',
                    },
                    legend: {
                        orient: 'vertical',
                        left: 10,
                        data: pieData.map((item) => item.name),
                    },
                    series: [
                        {
                            name: 'User Type',
                            type: 'pie',
                            radius: '50%',
                            center: ['50%', '60%'],
                            data: pieData,
                            emphasis: {
                                itemStyle: {
                                    shadowBlur: 10,
                                    shadowOffsetX: 0,
                                    shadowColor: 'rgba(0, 0, 0, 0.5)',
                                },
                            },
                        },
                    ],
                };

                // Set the chart options and render
                myChart.setOption(option);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    return <div ref={chartRef} style={{ width: '100%', height: '400px' }}></div>;
}

export default PieChart;
