import React, { useState, useEffect } from 'react';
import { Table, Typography, TableHead, TableRow, TableCell, TableBody, Button } from '@mui/material';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ITRevenuTable = () => {
    const [data, setData] = useState([]);
    const [expandedRows, setExpandedRows] = useState([]);
    const [itSupportPayments, setITSupportPayments] = useState([]);
    const [userName, setUserName] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch existing IT support payments
                const authToken = localStorage.getItem('token');
                const itSupportPaymentsResponse = await axios.get(`${process.env.REACT_APP_BASE_URL}/it-support-payments`, {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                    },
                });
                setITSupportPayments(itSupportPaymentsResponse.data);

                // Fetch data for expenses
                const expensesResponse = await axios.get(`${process.env.REACT_APP_BASE_URL}/expenses/displayAll`, {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                    },
                });

                const sortedExpenses = expensesResponse.data.sort((a, b) => new Date(b.date) - new Date(a.date));

                const combinedData = {};
                sortedExpenses.forEach(expense => {
                    if (expense.type === 'revenu') {
                        const monthYear = new Date(expense.date).toLocaleString('en-us', { month: 'long', year: 'numeric' });

                        if (!combinedData[monthYear]) {
                            combinedData[monthYear] = {
                                totalAmount: 0,
                                transactions: [],
                            };
                        }

                        combinedData[monthYear].totalAmount += expense.item.total;
                        combinedData[monthYear].transactions.push(expense);
                    }
                });

                const dataArray = Object.entries(combinedData).map(([monthYear, data]) => ({
                    monthYear,
                    totalAmount: data.totalAmount,
                    transactions: data.transactions,
                    isDetailsVisible: false,
                }));

                setData(dataArray);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();

        // Get userName from local storage
        const storedUserName = localStorage.getItem('userName');
        setUserName(storedUserName);
    }, []);

    const handleToggleDetails = (monthYear) => {
        setExpandedRows(prevExpandedRows => {
            const isDetailsVisible = !prevExpandedRows.includes(monthYear);
            return isDetailsVisible ? [...prevExpandedRows, monthYear] : prevExpandedRows.filter(row => row !== monthYear);
        });
    };

    const handleCreateITSupportPayment = async (monthYear) => {
        try {
            const authToken = localStorage.getItem('token');
            const existingPayment = itSupportPayments.find(payment => payment.month === monthYear);
            if (existingPayment && existingPayment.paymentStatus === 'Paid') {
                toast.error('Paiement déjà effectué');
            } else {
                // Ask for confirmation before proceeding
                const confirmPayment = window.confirm(`Confirmez-vous le paiement pour le mois de ${monthYear} ?`);
                if (confirmPayment) {
                    const newITSupportPayment = {
                        month: monthYear,
                        paymentStatus: 'Paid',
                        // You may need to replace this with the actual image link
                        imageLink: 'https://example.com/image.jpg',
                    };
                    await axios.post(`${process.env.REACT_APP_BASE_URL}/it-support-payments`, newITSupportPayment, {
                        headers: {
                            Authorization: `Bearer ${authToken}`,
                        },
                    });
                    // Refresh the IT support payments after creating a new entry
                    const itSupportPaymentsResponse = await axios.get(`${process.env.REACT_APP_BASE_URL}/it-support-payments`, {
                        headers: {
                            Authorization: `Bearer ${authToken}`,
                        },
                    });
                    toast.success('Paiement confirmé');
                    setITSupportPayments(itSupportPaymentsResponse.data);
                }
            }
        } catch (error) {
            toast.error(`Non autorisé, Uniquement IT ( tel : 623-98-96-47 )`);

            console.error('Error creating IT support payment:', error);
        }
    };

    function formatCurrency(amount) {
        if (amount) {
            return amount.toLocaleString('en-US', {
                style: 'currency',
                currency: 'GNF',
                minimumFractionDigits: 0,
            });
        } else {
            return '';
        }
    }

    function getMonthName(monthYear) {
        const [monthName, year] = monthYear.split(' ');
        const monthIndex = new Date(Date.parse(`${monthName} 1, ${year}`)).getMonth();
        return new Date(2000, monthIndex, 1).toLocaleString('en-US', { month: 'long' });
    }

    return (
        <>
            <Typography variant="h6" style={ { fontSize: '2rem', fontWeight: 'bold' } }>
                Commission Support IT 💻
            </Typography>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Month</TableCell>
                        <TableCell>Total Revenu</TableCell>
                        <TableCell>Commission (4%)</TableCell>
                        { userName === "Sayon Guilavogui" && (
                            <>
                                <TableCell>Details</TableCell>
                                <TableCell>IT Support Payment</TableCell>
                            </>
                        ) }
                        <TableCell>Payment Status</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    { data.map(entry => (
                        <TableRow
                            key={ entry.monthYear }
                            style={ {
                                backgroundColor: itSupportPayments.some(payment => payment.month === entry.monthYear && payment.paymentStatus === 'Paid') ? 'lightgreen' : 'lightyellow'
                            } }
                        >
                            <TableCell>{ entry.monthYear }</TableCell>
                            <TableCell>{ formatCurrency(entry.totalAmount) }</TableCell>
                            <TableCell>{ formatCurrency(0.04 * entry.totalAmount) }</TableCell>
                            { userName === "Sayon Guilavogui" && (
                                <>
                                    <TableCell>
                                        <Button onClick={ () => handleToggleDetails(entry.monthYear) }>
                                            { expandedRows.includes(entry.monthYear) ? 'Hide Details' : 'Show Details' }
                                        </Button>
                                        { expandedRows.includes(entry.monthYear) && (
                                            <div>
                                                { entry.transactions.map(transaction => (
                                                    <div key={ transaction._id }>
                                                        { `Amount: ${formatCurrency(transaction.item.total)}, Added By: ${transaction.createdByWho.name}, Tel: ${transaction.modeDePaiement}` }
                                                    </div>
                                                )) }
                                            </div>
                                        ) }
                                    </TableCell>
                                    <TableCell>
                                        <Button onClick={ () => handleCreateITSupportPayment(entry.monthYear) }>
                                            Confirmez le paiement
                                        </Button>
                                    </TableCell>
                                </>
                            ) }
                            <TableCell>
                                {/* Display payment status based on IT support payments */ }
                                { itSupportPayments.map(payment => (
                                    payment.month === entry.monthYear && payment.paymentStatus
                                )) }
                            </TableCell>
                        </TableRow>
                    )) }
                </TableBody>
            </Table>
            <ToastContainer position="top-center" />
        </>
    );
};

export default ITRevenuTable;
