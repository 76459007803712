import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
    Table,
    TableBody,
    TableCell, Grid,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    Container,
    FormControl,
    InputLabel,
    Select,Button,
    MenuItem,
} from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';


import CreateTicketFrom from '../forms/CreateTicketForm'

function UserTicketTable() {
    const [userTickets, setUserTickets] = useState([]);
    const [filterStatus, setFilterStatus] = useState(''); // State for ticket status filter
    const [filterType, setFilterType] = useState(''); // State for type of ticket filter
    const authToken = localStorage.getItem('token');
    const [newTicket, setNewTicket] = useState(false);
    const [showCreateTicketForm, setShowCreateTicketForm] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/ticket/listForUser`, {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                    },
                });
                setUserTickets(response.data.userTickets);
            } catch (error) {
                toast.error('Try to logout And Login Back', {
                    position: 'top-center',
                    autoClose: 5000, // 5 seconds
                });

                console.error('Error fetching user tickets:', error);
            }
        };

        fetchData();
    }, [authToken]);

    const getStatusColor = (status) => {
        switch (status) {
            case 'pending':
                return '#FFCC80'; // Light Orange
            case 'processing':
                return '#FFFF99'; // Light Yellow
            case 'completed':
                return '#B2FF66'; // Light Green
            case 'cancelled':
                return '#FF9999'; // Light Red
            default:
                return '#E0E0E0'; // Light Gray (default)
        }
    };


    const statusCounts = {
        pending: 0,
        processing: 0,
        completed: 0,
        cancelled: 0,
    };
    // Count the tasks for each status
 
    // Filtered user tickets based on selected status and type
    // Filtered user tickets based on selected status and type
    const filteredTickets = userTickets
        .filter(
            (ticket) =>
                (!filterStatus || ticket.status === filterStatus) && (!filterType || ticket.typeOfTicket === filterType)
        )
        .sort((a, b) => new Date(b.date) - new Date(a.date));

    filteredTickets.forEach((task) => {
        statusCounts[task.status]++;
    });
    
    const handleNewTicketClick = () => {
        setNewTicket(true);
        setShowCreateTicketForm(true);
    };

    const handleSeeMyTicketsClick = () => {
        setNewTicket(false);
        setShowCreateTicketForm(false);
    };

    const formatDateToFrench = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        if (isNaN(date.getTime())) return 'Date invalide'; // Return 'Invalid date' in French
        return date.toLocaleDateString('fr-FR', {
            day: 'numeric',
            month: 'long',
            year: 'numeric'
        });
    };
    return (
        <Container>
            <Typography variant="h4" gutterBottom>
                My Tickets
            </Typography>
            <div>
                {showCreateTicketForm ? (
                    <>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={handleSeeMyTicketsClick}
                        >
                            See My Tickets
                        </Button>
                        <CreateTicketFrom /> {/* Correct component name and usage */}
                    </>
                ) : (
                    <Button
                    style={{
                        marginBottom: '10px',
                    }}
                        variant="contained"
                        color="primary"
                        onClick={handleNewTicketClick}
                    >
                        New Ticket
                    </Button>
                )}
            </div>

            {newTicket ? null : (
                <>
                    <FormControl style={{ minWidth: 150, marginRight: 20 }}>
                        <InputLabel
                            style={{
                                backgroundColor: 'white', // Set the background color here
                                padding: '0 5px', // Optional: Adjust padding for better appearance
                            }}
                        >
                            Status
                        </InputLabel>
                        <Select
                            value={filterStatus}
                            onChange={(e) => setFilterStatus(e.target.value)}
                        >
                            <MenuItem value="">All</MenuItem>
                            <MenuItem value="pending">Pending</MenuItem>
                            <MenuItem value="processing">Processing</MenuItem>
                            <MenuItem value="completed">Completed</MenuItem>
                            <MenuItem value="cancelled">Cancelled</MenuItem>
                        </Select>
                    </FormControl>

                    <FormControl style={{ minWidth: 150 }}>
                        <InputLabel 
                        
                            style={{
                                backgroundColor: 'white', // Set the background color here
                                padding: '0 5px', // Optional: Adjust padding for better appearance
                            }}>Type of Ticket</InputLabel>

                        <Select
                            value={filterType}
                            onChange={(e) => setFilterType(e.target.value)}
                        >
                            <MenuItem value="">All</MenuItem>
                            {/* Replace with your actual types of tickets */}
                            <MenuItem value="access">Access</MenuItem>
                            <MenuItem value="other">Other</MenuItem>
                        </Select>
                    </FormControl>

                    <div>
                        <Grid 
                        style={{
                            marginTop: '10px',
                        }}
                        container spacing={2}>

                            {Object.entries(statusCounts).map(([status, count]) => (

                                <Grid item xs={3} sm={3}>
                                    <div key={status}>

                                        <Typography
                                            style={{
                                                fontWeight: 'bold',
                                            }}
                                        >{`${status}: ${count}`}</Typography>
                                    </div>
                                </Grid>
                            ))}


                        </Grid>
                    </div>


                    <TableContainer 
                    style={{
                        height: '750px',
                        marginTop: '20px'
                    }}
                    component={Paper}>
                        <Table>
                            <TableHead
                                style={{
                                    backgroundColor: '#78AEF9',
                                    position: 'sticky',
                                    top: 0,
                                    zIndex: 1000,
                                    fontWeight: 'bold', // Apply bold font
                                }}
                            >
                                <TableRow>
                                    <TableCell 
                                    style={{
                fontSize: '0.96rem',
                fontFamily: 'monospace',
                fontWeight: 'bold',
            }}>Name</TableCell>

                                    <TableCell 
                                    style={{
                fontSize: '0.96rem',
                fontFamily: 'monospace',
                fontWeight: 'bold',
            }}>Status</TableCell>

                                    <TableCell 
                                    style={{
                fontSize: '0.96rem',
                fontFamily: 'monospace',
                fontWeight: 'bold',
            }}>Type</TableCell>

                                    <TableCell 
                                    style={{
                fontSize: '0.96rem',
                fontFamily: 'monospace',
                fontWeight: 'bold',
            }}>Name</TableCell>

                                    <TableCell 
                                    style={{
                fontSize: '0.96rem',
                fontFamily: 'monospace',
                fontWeight: 'bold',
            }}>Tel</TableCell>

                                    <TableCell 
                                    style={{
                fontSize: '0.96rem',
                fontFamily: 'monospace',
                fontWeight: 'bold',
            }}>Content</TableCell>

                                    <TableCell 
                                    style={{
                fontSize: '0.96rem',
                fontFamily: 'monospace',
                fontWeight: 'bold',
            }}>Admin Comment</TableCell>

                                    <TableCell 
                                    style={{
                fontSize: '0.96rem',
                fontFamily: 'monospace',
                fontWeight: 'bold',
            }}>Date</TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredTickets.map((ticket) => (
                                    <TableRow
                                        key={ticket._id}
                                        style={{ backgroundColor: getStatusColor(ticket.status) }}
                                    >
                                        <TableCell>{ticket.name}</TableCell>
                                        <TableCell>{ticket.status}</TableCell>
                                        <TableCell>{ticket.typeOfTicket}</TableCell>
                                        <TableCell>{ticket.userName}</TableCell>
                                        <TableCell>{ticket.userTel}</TableCell>
                                        <TableCell>{ticket.ticketContent}</TableCell>
                                        <TableCell>{ticket.adminComment}</TableCell>
                                        <TableCell>
                                            
                                            {/* {new Date(ticket.date).toLocaleString()} */}
                                            {formatDateToFrench(ticket.date)}
                                            </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <ToastContainer position="top-center" />

                </>
            )}
        </Container>
    );
}

export default UserTicketTable;