import React, { useEffect, useState } from 'react';
import {
    Table,
    Typography,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    Modal,
    TextField,
    Box,
} from '@mui/material';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ClientTableByAgentTel = () => {
    const [clients, setClients] = useState([]);
    const [editClient, setEditClient] = useState(null);
    const [filters, setFilters] = useState({
        firstName: '',
        lastName: '',
        address: '',
        phoneNumber: '',
        status: '',
        type: '',
    });

    useEffect(() => {
        // Fetch data from the API with authentication token
        const token = localStorage.getItem('token'); // Replace with your actual auth token

        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/clientsByTel`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                if (response.status === 200) {
                    // Sort clients by date (newest to oldest)
                    const sortedClients = response.data.sort((a, b) => new Date(b.date) - new Date(a.date));
                    setClients(sortedClients);
                } else {
                    console.error('Error fetching data:', response.statusText);
                }
            } catch (error) {
                // toast.error(' Check Your Connection, or Re-Login', {
                //     position: 'top-center',
                //     autoClose: 5000,
                // });
                console.error('Error fetching data:', error.message);
            }
        };

        fetchData();
    }, []);

    const handleEdit = (client) => {
        setEditClient(client);
    };

    const handleCloseEditModal = () => {
        setEditClient(null);
    };

    const handleSaveEdit = async () => {
        // Fetch API with authentication token
        const token = localStorage.getItem('token'); // Replace with your actual auth token

        try {
            const response = await axios.put(
                `${process.env.REACT_APP_BASE_URL}/clients/${editClient._id}`,
                editClient,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (response.status === 200) {
                toast.success('Client data updated successfully', {
                    position: 'top-center',
                    autoClose: 5000,
                });
                // Close the modal and update the data
                handleCloseEditModal();
                // Update the client in the clients array
                setClients((prevClients) =>
                    prevClients.map((prevClient) =>
                        prevClient._id === editClient._id ? editClient : prevClient
                    )
                );
            } else {
                toast.error('Error updating client data', {
                    position: 'top-center',
                    autoClose: 5000,
                });
            }
        } catch (error) {
            toast.error('An unexpected error occurred', {
                position: 'top-center',
                autoClose: 5000,
            });
            console.error('Unexpected error:', error);
        }
    };

    const applyFilters = (client) => {
        const { firstName, lastName, address, phoneNumber, status, type } = filters;

        return (
            client.firstName.toLowerCase().includes(firstName.toLowerCase()) &&
            client.lastName.toLowerCase().includes(lastName.toLowerCase()) &&
            client.address.toLowerCase().includes(address.toLowerCase()) &&
            client.phoneNumber.toLowerCase().includes(phoneNumber.toLowerCase()) &&
            client.status.toLowerCase().includes(status.toLowerCase()) &&
            client.type.toLowerCase().includes(type.toLowerCase())
        );
    };


    const filteredClients = clients.filter(applyFilters);


    const [showFilters, setShowFilters] = useState(false);

    const toggleFilters = () => {
        setShowFilters((prevShowFilters) => !prevShowFilters);
    };


    const formatDateToFrench = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        if (isNaN(date.getTime())) return 'Date invalide'; // Return 'Invalid date' in French
        return date.toLocaleDateString('fr-FR', {
            day: 'numeric',
            month: 'long',
            year: 'numeric'
        });
    };

    return (
        <>
            <Typography variant="h6" style={{ fontSize: '1.5rem', fontWeight: 'bold', margin: '12px 0 12px 0px' }}>
                Client You Registered 👨‍👩‍👦
                Total : {clients.length}

            </Typography>



            <Button
                variant="contained"
                onClick={toggleFilters}
                style={{
                    backgroundColor: 'blue',
                    color: 'white',
                    minWidth: '120px',
                    marginBottom: '10px',
                }}
            >
                {showFilters ? 'Hide Filters' : 'Show Filters'}
            </Button>


            {/* Filter Inputs */}

            {
                showFilters && (
            <Box mb={2}>
                <TextField
                    label="First Name"
                    variant="outlined"
                    size="small"
                    margin="dense"
                    value={filters.firstName}
                    onChange={(e) => setFilters({ ...filters, firstName: e.target.value })}
                />
                <TextField
                    label="Last Name"
                    variant="outlined"
                    size="small"
                    margin="dense"
                    value={filters.lastName}
                    onChange={(e) => setFilters({ ...filters, lastName: e.target.value })}
                />
                <TextField
                    label="Address"
                    variant="outlined"
                    size="small"
                    margin="dense"
                    value={filters.address}
                    onChange={(e) => setFilters({ ...filters, address: e.target.value })}
                />
                <TextField
                    label="Phone Number"
                    variant="outlined"
                    size="small"
                    margin="dense"
                    value={filters.phoneNumber}
                    onChange={(e) => setFilters({ ...filters, phoneNumber: e.target.value })}
                />
                <TextField
                    label="Status"
                    variant="outlined"
                    size="small"
                    margin="dense"
                    value={filters.status}
                    onChange={(e) => setFilters({ ...filters, status: e.target.value })}
                />
                <TextField
                    label="Type"
                    variant="outlined"
                    size="small"
                    margin="dense"
                    value={filters.type}
                    onChange={(e) => setFilters({ ...filters, type: e.target.value })}
                />
            </Box>


                )}



          

            <TableContainer component={Paper}>
                <Table>
                    <TableHead
                        style={{
                            backgroundColor: '#78AEF9',
                            position: 'sticky',
                            top: 0,
                            zIndex: 1000,
                            fontWeight: 'bold',
                        }}
                    >
                        <TableRow>
                            <TableCell>First Name</TableCell>
                            <TableCell>Last Name</TableCell>
                            <TableCell>Business</TableCell>
                            <TableCell>Activity</TableCell>
                            <TableCell>Phone Number</TableCell>
                            <TableCell>Address</TableCell>
                            <TableCell>Date of First Contact</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Type</TableCell>
                            <TableCell>Comment</TableCell>
                            <TableCell>Date</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {filteredClients.map((client, index) => (
                            <TableRow
                                key={client._id}
                                style={{
                                    backgroundColor: index % 2 === 0 ? '#ADD8E6' : '#73c3e6',
                                }}
                            >
                                <TableCell>{client.firstName}</TableCell>
                                <TableCell>{client.lastName}</TableCell>
                                <TableCell>{client.business}</TableCell>
                                <TableCell>{client.activity}</TableCell>
                                <TableCell>{client.phoneNumber}</TableCell>
                                <TableCell>{client.address}</TableCell>
                                <TableCell>
                                    {/* {new Date(client.dateOfFirstContact).toLocaleString()} */}
                                    {formatDateToFrench(client.dateOfFirstContact)}
                                    </TableCell>
                                <TableCell>{client.status}</TableCell>
                                <TableCell>{client.type}</TableCell>
                                <TableCell>{client.comment}</TableCell>
                                <TableCell>
                                    {formatDateToFrench(client.date)}
                                    {/* {new Date(client.date).toLocaleString()} */}
                                    </TableCell>
                                <TableCell>
                                    <Button onClick={() => handleEdit(client)}>Edit</Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>

                </Table>
            </TableContainer>

            <ToastContainer position="top-center" />

            {/* Edit Modal */}
            {/* <Modal open={Boolean(editClient)} onClose={handleCloseEditModal}>
                <Box
                    sx={{
                        position: 'absolute',
                        width: 400,
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <Typography variant="h6" gutterBottom>
                        Edit Client
                    </Typography>
                    <TextField
                        label="Name"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={editClient?.name || ''}
                        onChange={(e) => setEditClient((prev) => ({ ...prev, name: e.target.value }))}
                    />
                    <TextField
                        label="Address"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={editClient?.address || ''}
                        onChange={(e) => setEditClient((prev) => ({ ...prev, address: e.target.value }))}
                    />
                    <TextField
                        label="Tel"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={editClient?.tel || ''}
                        onChange={(e) => setEditClient((prev) => ({ ...prev, tel: e.target.value }))}
                    />
                    <TextField
                        label="Status"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={editClient?.status || ''}
                        onChange={(e) => setEditClient((prev) => ({ ...prev, status: e.target.value }))}
                    />
                    <TextField
                        label="Type"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={editClient?.type || ''}
                        onChange={(e) => setEditClient((prev) => ({ ...prev, type: e.target.value }))}
                    />
                    <TextField
                        label="Comment"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={editClient?.comment || ''}
                        onChange={(e) => setEditClient((prev) => ({ ...prev, comment: e.target.value }))}
                    />
                    <Button onClick={handleSaveEdit} variant="contained" color="primary">
                        Save
                    </Button>
                </Box>
            </Modal> */}


            <Modal open={Boolean(editClient)} onClose={handleCloseEditModal}>
                <Box
                    sx={{
                        position: 'absolute',
                        width: 400,
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <Typography variant="h6" gutterBottom>
                        Edit Client
                    </Typography>
                    <TextField
                        label="First Name"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={editClient?.firstName || ''}
                        onChange={(e) => setEditClient((prev) => ({ ...prev, firstName: e.target.value }))}
                    />
                    <TextField
                        label="Last Name"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={editClient?.lastName || ''}
                        onChange={(e) => setEditClient((prev) => ({ ...prev, lastName: e.target.value }))}
                    />

                    <TextField
                        label="Business"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={editClient?.business || ''}
                        onChange={(e) => setEditClient((prev) => ({ ...prev, business: e.target.value }))}
                    />

                    <TextField
                        label="Activity"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={editClient?.activity || ''}
                        onChange={(e) => setEditClient((prev) => ({ ...prev, activity: e.target.value }))}
                    />

                    <TextField
                        label="Phone Number"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={editClient?.phoneNumber || ''}
                        onChange={(e) => setEditClient((prev) => ({ ...prev, phoneNumber: e.target.value }))}
                    />

                    <TextField
                        label="Address"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={editClient?.address || ''}
                        onChange={(e) => setEditClient((prev) => ({ ...prev, address: e.target.value }))}
                    />

                    <TextField
                        label="Date of First Contact"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={editClient?.dateOfFirstContact || ''}
                        onChange={(e) => setEditClient((prev) => ({ ...prev, dateOfFirstContact: e.target.value }))}
                    />

                    <TextField
                        label="Status"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={editClient?.status || ''}
                        onChange={(e) => setEditClient((prev) => ({ ...prev, status: e.target.value }))}
                    />

                    <TextField
                        label="Type"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={editClient?.type || ''}
                        onChange={(e) => setEditClient((prev) => ({ ...prev, type: e.target.value }))}
                    />

                    <TextField
                        label="Comment"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={editClient?.comment || ''}
                        onChange={(e) => setEditClient((prev) => ({ ...prev, comment: e.target.value }))}
                    />

                    {/* Continue with similar changes for other fields */}
                    <Button onClick={handleSaveEdit} variant="contained" color="primary">
                        Save
                    </Button>
                </Box>
            </Modal>

        </>
    );
};

export default ClientTableByAgentTel;
