import React, { useEffect, useRef } from 'react';
import * as echarts from 'echarts';

function StatusPieChart() {
    const chartRef = useRef(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch data from the API
                const response = await fetch(`${process.env.REACT_APP_BASE_URL}/goods-tracking/displayAll`, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    },
                });
                const data = await response.json();

                // Process the data to extract status values and their counts
                const statusData = {};
                data.forEach((item) => {
                    const status = item.status;
                    if (status) {
                        if (statusData[status]) {
                            statusData[status] += 1;
                        } else {
                            statusData[status] = 1;
                        }
                    }
                });

                // Prepare data for the pie chart
                const pieData = Object.keys(statusData).map((status) => ({
                    value: statusData[status],
                    name: status,
                }));

                // Initialize ECharts and create the chart
                const myChart = echarts.init(chartRef.current);
                const option = {
                    title: {
                        text: '',
                        left: 'center',
                    },
                    tooltip: {
                        trigger: 'item',
                        formatter: '{a} <br/>{b} : {c} ({d}%)',
                    },
                    // legend: {
                    //     orient: 'vertical',
                    //     left: 'left',
                    // },
                    series: [
                        {
                            name: 'Status',
                            type: 'pie',
                            radius: '50%',
                            data: pieData,
                            emphasis: {
                                itemStyle: {
                                    shadowBlur: 10,
                                    shadowOffsetX: 0,
                                    shadowColor: 'rgba(0, 0, 0, 0.5)',
                                },
                            },
                        },
                    ],
                };

                // Set the chart options and render
                myChart.setOption(option);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    return <div ref={chartRef} style={{ width: '100%', height: '400px' }}></div>;
}

export default StatusPieChart;
