import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import axios from 'axios';
import {
    Container, Typography, TextField, Button, CircularProgress, FormControl,
    InputLabel, MenuItem,
Select } from '@mui/material';

function Ticket() {
    const [userTel, setUserTel] = useState('');
    const [itemIdToRemove, setItemIdToRemove] = useState('');
    const [ticketContent, setTicketContent] = useState('');

    
    const [isSubmitting, setIsSubmitting] = useState(false);
   

    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        // setIsSubmitting(true);

        console.log(`userTel ${userTel}`);
        console.log(`itemIdToRemove ${itemIdToRemove}`);
        console.log(`ticketContent ${ticketContent}`);
        const authToken = localStorage.getItem('token');
        const authorizationHeader = `Bearer ${authToken}`;

        const removeAccessData = {
            userTel,
            itemIdToRemove,
            
        }

        console.log(removeAccessData);

        if (
            !removeAccessData.userTel ||
            !removeAccessData.itemIdToRemove 
        ) {
            toast.error('Please fill in all required fields', {
                position: 'top-center',
                autoClose: 5000, // 5 seconds
            });
            return; // Stop the submission if any required field is empty
        }

        console.log(removeAccessData);
        try {
         
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/user/removeAcessListItem`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: authorizationHeader,
                },
                body: JSON.stringify(removeAccessData), // JSON.stringify the body
            });


            if (response.ok) {
                // console.log(`${t("Passwordchangesuccessful")}`, response.data);
                toast.success( `${removeAccessData.itemIdToRemove} removed successful`, {
                    position: 'top-center',
                    autoClose: 5000, // 5 seconds
                });


                // // Delay the navigation by 3 seconds
                // setTimeout(() => {
                //     navigate('/'); // Use the navigate function to redirect

                // }, 3000);
                setItemIdToRemove('')



            } else {
                // console.error('Password change failed', error);
                toast.error(`error`, {
                    position: 'top-center',
                    autoClose: 5000, // 5 seconds
                });
                setIsSubmitting(false);


            }

           

        } catch (error) {
            toast.error(`${error}`, {
                position: 'top-center',
                autoClose: 5000, // 5 seconds
            });
            console.error('Ticket failed:', error);
        }
    };

    const containerStyle = {
        marginTop: '2rem',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    };

    const formStyle = {
        width: '100%',
        marginTop: '2rem',
    };

    const buttonStyle = {
        marginTop: '2rem',
    };

    const goToRegistrationPage = () => {
        navigate('/register')
    }

    const handleTicketContentChange = (e) => {
        setItemIdToRemove(e.target.value);
    };

    return (
        <Container component="main" maxWidth="xs" style={containerStyle}>
            <Typography variant="h5"> Remove Access  </Typography>
        

            <form onSubmit={handleSubmit} style={formStyle}>
                <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    label="userTel"
                    type="userTel"
                    value={userTel}
                    onChange={(e) => setUserTel(e.target.value)}
                />
           

                <FormControl style={{ marginBottom: '16px', minWidth: '100%' }}>
                    <InputLabel>Type of Ticket</InputLabel>
                    <Select
                        label="Ticket Title"
                        userTel="ticketContent"
                        value={itemIdToRemove}
                        onChange={handleTicketContentChange}
                        fullWidth

                    >


                        <MenuItem value="allAccess">allAccess</MenuItem>
                        <MenuItem value="createExpenses">createExpenses</MenuItem>
                        <MenuItem value="getAllBudget">getAllBudget</MenuItem>
                        <MenuItem value="createExpense">createExpense</MenuItem>
                        <MenuItem value="getAllExpenses">getAllExpenses</MenuItem>
                        <MenuItem value="UpdateExpensesById">UpdateExpensesById</MenuItem>
                        <MenuItem value="deleteExpensesById">deleteExpensesById</MenuItem>
                        <MenuItem value="createGoodsTracking">createGoodsTracking</MenuItem>
                        <MenuItem value="getAllGoods">getAllGoods</MenuItem>
                        <MenuItem value="getAllGoodsForUser">getAllGoodsForUser</MenuItem>
                        <MenuItem value="getAllGoodsForSender">getAllGoodsForSender</MenuItem>
                        <MenuItem value="updateGoodsTrackingBySender">updateGoodsTrackingBySender</MenuItem>
                        <MenuItem value="deleteGoodsTracking">deleteGoodsTracking</MenuItem>
                        <MenuItem value="createTickets">createTickets</MenuItem>
                        <MenuItem value="getAllTicketByAdmin">getAllTicketByAdmin</MenuItem>
                        <MenuItem value="getAllTicketByUser">getAllTicketByUser</MenuItem>
                        <MenuItem value="createUser">createUser</MenuItem>
                        <MenuItem value="adminUpdateUserById">adminUpdateUserById</MenuItem>
                        <MenuItem value="removeAccessListItem">removeAccessListItem</MenuItem>
                        <MenuItem value="getAllUsers">getAllUsers</MenuItem>
                        <MenuItem value="deleteUserById">deleteUserById</MenuItem>
                        <MenuItem value="getUserById">getUserById</MenuItem>

                        {/* Add more options as needed */}
                    </Select>
                </FormControl>


               
            
                

                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    // style={buttonStyle}
                    style={{ marginTop: '20px', position: 'relative' }} // Position relative for CircularProgress
                    disabled={isSubmitting} // Disable button while submitting
                >
                    {isSubmitting ? (
                        <CircularProgress size={24} color="inherit" /> // Show CircularProgress while submitting
                    ) : (
                        'Remove'
                    )}
                </Button>
            </form>
            <ToastContainer position="top-center" autoClose={5000} />

        </Container>
    );
}

export default Ticket;
