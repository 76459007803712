import React from 'react';
import axios from 'axios';
import { Dialog, DialogContent, IconButton, Button } from '@mui/material';
import { MdClose } from 'react-icons/md';


import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';




function ImageModal({ imageUrl, goodsID, setUpdateTables, onClose }) {


    console.log(`imageUrl ${imageUrl}`);
    console.log(`goodsID ${goodsID}`);
    const handleClose = () => {
        onClose();
    };
    const handleDeleteClick = async () => {
        const confirmDelete = window.confirm('Êtes-vous sûr de vouloir supprimer cette image ?');
        if (!confirmDelete) {
            return; // If user cancels, do nothing
        }

        try {
            const deleteUrl = `${process.env.REACT_APP_BASE_URL}/goods/${goodsID}/remove-image/${encodeURIComponent(imageUrl)}`;
            await axios.delete(deleteUrl);
            toast.success('Image deleted successfully', {
                position: 'bottom-center',
                autoClose: 5000, // 5 seconds
            });
            onClose();
            setUpdateTables(prevNum => prevNum + 1);
        } catch (error) {
            console.error('Error deleting image:', error);
            alert('Failed to delete image');
        }
    };

    return (
        <>

        <Dialog open={ true } onClose={ handleClose } maxWidth="md" fullWidth>
            <DialogContent>
                <IconButton style={ { position: 'absolute', top: '5px', right: '5px', zIndex: '1' } } onClick={ handleClose }>
                    <MdClose />
                </IconButton>
                <img src={ imageUrl } alt="Enlarged Image" style={ { width: '100%', height: 'auto', maxHeight: '80vh' } } />
                <div style={ { textAlign: 'center', marginTop: '10px' } }>
                    <Button variant="contained" color="error" onClick={ handleDeleteClick }>Supprimer cette image ?</Button>
                </div>
            </DialogContent>

        </Dialog>
            <ToastContainer position="Bottom-center" autoClose={ 5000 } />
        </>
    );
}

export default ImageModal;
