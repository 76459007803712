import React, { useState } from 'react';
import { Button, Grid } from '@mui/material';

import ClientForm from '../forms/ClientForm';
import ClientsTableByUser from './ClientsTableByUser';
import ClientsTable from './ClientsTable';

const ClientsPage = () => {
    const [newClientForm, setNewClientForm] = useState(false);
    const [displayAllClient, setDisplayAllClient] = useState(false);

    const handleNewClientForm = () => {
        setNewClientForm((prevShowForm) => !prevShowForm);
    };

    const handleDisplayAllClient = () => {
        setDisplayAllClient((prevShowAllClient) => !prevShowAllClient);
    };

    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                    <Button
                        onClick={handleNewClientForm}
                        style={{
                            backgroundColor: 'blue',
                            color: 'white',
                            minWidth: '120px',
                            marginBottom: '10px',
                        }}
                    >
                        {newClientForm ? 'Client Table' : 'New Client'}
                    </Button>
                </Grid>

                {!newClientForm && (
                   <Grid item xs={12} md={12}>
                    <Button
                        onClick={handleDisplayAllClient}
                        style={{
                            backgroundColor: 'green',
                            color: 'white',
                            minWidth: '120px',
                            marginBottom: '10px',
                        }}
                    >
                        {displayAllClient ? 'Display My Clients' : 'Display All Clients'}
                    </Button>
                </Grid> 
                )}

                
            </Grid>

            {newClientForm  && <ClientForm />}

            {!newClientForm && displayAllClient && <ClientsTable />}

            {!newClientForm && !displayAllClient && <ClientsTableByUser />}
        </div>
    );
};

export default ClientsPage;
