import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';




import Dropzone from 'react-dropzone';
import { Image, Video } from 'cloudinary-react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ListIcon from '@mui/icons-material/List';
import Fab from '@mui/material/Fab';



import {
    TextField,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Typography,
    Container,
    Paper, Stack, CircularProgress
} from '@mui/material';

const ExpenseForm = () => {
    const [uploadedImages, setUploadedImages] = useState([]);
    const navigate = useNavigate();



    const [isSubmitting, setIsSubmitting] = useState(false);

    const [expenseData, setExpenseData] = useState({
        type: '',
        item: {
            itemName: '',
            unit: '',
            quantity: 1,
            price: 0,
        },
        status: '',
        date: '',
        imagesLinks: ['111111', '22222'],
        byWho: '',
        toWhom: '',
        comment: '',
        budgetId: '',
        budgetName: '',
        transactionType: 'added',
        modeDePaiement: '',
        fraisDeRetrait: 0,
        fraisDeDepot: 0,
    });

    // useEffect(() => {
    //     const userRole = localStorage.getItem('userRole');
    //     if (userRole && userRole !== 'admin') {
    //         window.location.href = '/goodsTableForUser';

    //     }
    // }, []);


    const handleTypeChange = (e) => {
        setExpenseData({
            ...expenseData,
            type: e.target.value,
        });
    };

    const handleItemNameChange = (e) => {
        setExpenseData({
            ...expenseData,
            item: {
                ...expenseData.item,
                itemName: e.target.value,
            },
        });
    };

    const handleUnitChange = (e) => {
        setExpenseData({
            ...expenseData,
            item: {
                ...expenseData.item,
                unit: e.target.value,
            },
        });
    };

    const handleQuantityChange = (e) => {
        setExpenseData({
            ...expenseData,
            item: {
                ...expenseData.item,
                quantity: Number(e.target.value),
            },
        });
    };

    const handlePriceChange = (e) => {
        setExpenseData({
            ...expenseData,
            item: {
                ...expenseData.item,
                price: Number(e.target.value),
            },
        });
    };

    const handleStatusChange = (e) => {
        setExpenseData({
            ...expenseData,
            status: e.target.value,
        });
    };

    const handleImagesLinksChange = (e) => {
        setExpenseData({
            ...expenseData,
            imagesLinks: e.target.value.split(','),
        });
    };

    const handleToWhomChange = (e) => {
        setExpenseData({
            ...expenseData,
            toWhom: e.target.value,
        });
    };

    const handleByWhoChange = (e) => {
        setExpenseData({
            ...expenseData,
            byWho: e.target.value,
        });
    };


    const handleDateChange = (e) => {
        setExpenseData({
            ...expenseData,
            date: e.target.value,
        });
    };

    

    const handleCommentChange = (e) => {
        setExpenseData({
            ...expenseData,
            comment: e.target.value,
        });
    };

    const handleBudgetIdChange = (e) => {
        setExpenseData({
            ...expenseData,
            budgetId: e.target.value,
        });


       

    };

    const handleBudgetNameChange = (e) => {
        const newBudgetName = e.target.value;

        // Check if the new transactionType is 'added' and set default values
        if (newBudgetName === 'Compte Subvention') {
            setExpenseData((prevData) => ({
                ...prevData,
                budgetName: newBudgetName,
                budgetId: '65080dd52f9dc9494c942673',
            }));
        }

        if (newBudgetName === 'Compte Revenu') {
            setExpenseData((prevData) => ({
                ...prevData,
                budgetName: newBudgetName,
                budgetId: '650c409731f999545bbac85a',
            }));
        }
    };

    const handleModePaiementNameChange = (e) => {
        setExpenseData({
            ...expenseData,
            modeDePaiement: e.target.value,
        });
    };

    const handleFraideRetraitChange = (e) => {
        setExpenseData({
            ...expenseData,
            fraisDeRetrait: Number(e.target.value),
        });
    };


    const handleFraideDepotChange = (e) => {
        setExpenseData({
            ...expenseData,
            fraisDeDepot: Number(e.target.value),
        });
    };


    // const handleTransactionTypeChange = (e) => {
    //     setExpenseData({
    //         ...expenseData,
    //         transactionType: e.target.value,
    //     });
    // };

    const handleTransactionTypeChange = (e) => {
        const newTransactionType = e.target.value;
        setExpenseData({
            ...expenseData,
            transactionType: newTransactionType,
        });

        // Check if the new transactionType is 'added' and set default values
        if (newTransactionType === 'added') {
            setExpenseData((prevData) => ({
                ...prevData,
                // type: 'recharge du Compte',
                item: {
                    ...prevData.item,
                    unit: 'Cash',
                    quantity: 1,
                },
                status: 'completed',
            }));
        }
    };


 


    const handleSubmit = async () => {
        const authToken = localStorage.getItem('token');
        const authorizationHeader = `Bearer ${authToken}`;
        console.log(expenseData);
        setIsSubmitting(true)

        // Check if required fields are empty
        if (
            !expenseData.item.itemName ||
            !expenseData.budgetName ||
            !expenseData.transactionType ||
            !expenseData.item.quantity ||
            !expenseData.item.price ||
            expenseData.item.quantity <= 0 || // Check if quantity is less than or equal to zero
            expenseData.item.price <= 0 // Check if price is less than or equal to zero

        ) {
            toast.error('Please fill in all required fields', {
                position: 'top-center',
                autoClose: 5000, // 5 seconds
            });
            setIsSubmitting(false);

            return; // Stop the submission if any required field is empty
        }

        try {

            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/expenses/create`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: authorizationHeader,
                },
                body: JSON.stringify(expenseData), // JSON.stringify the body
            });

            if (response.ok) {
                const data = await response.json();


                // Handle success
                toast.success('Data Submitted Successfully', {
                    position: 'top-center',
                    autoClose: 5000, // 5 seconds
                });


                
                console.log('Expense submitted successfully:', data);

                // Clear the form fields after successful submission
                setExpenseData({
                    type: '',
                    item: {
                        itemName: '',
                        unit: '',
                        quantity: 1,
                        price: 0,
                    },
                    status: '',
                    imagesLinks: [],
                    toWhom: '',
                    comment: '',
                    budgetId: '',
                    budgetName: '',
                    transactionType: 'added',
                    modeDePaiement: '',
                    fraisDeRetrait: 0,
                });

                setTimeout(() => {
                    navigate('/RevenuTable', { replace: true });


                }, 3000);





            } else {
                // Handle errors
                toast.error('error', {
                    position: 'top-center',
                    autoClose: 5000, // 5 seconds
                });
                setIsSubmitting(false);

            }
           

        } catch (error) {
            setIsSubmitting(false);

            // Handle errors
            toast.error(error.message, {
                position: 'top-center',
                autoClose: 5000, // 5 seconds
            });
            console.error('Error:', error);
            console.error('Error Stack:', error.stack);
        }
    };





    const cldNme = `${process.env.REACT_APP_CLOUDINARY_CLDNME_URL}`;
    const handleImageUpload = async (files) => {
        try {
            const uploadPromises = files.map((file) => {
                const formData = new FormData();
                formData.append('file', file);
                formData.append('upload_preset', `${process.env.REACT_APP_CLOUDINARY_PRESET}`);// Replace with your Cloudinary upload preset


                return fetch(`https://api.cloudinary.com/v1_1/${cldNme}/image/upload`, {
                    method: 'POST',
                    body: formData,
                })
                    .then((response) => response.json())
                    .then((data) => data.secure_url);
            });

            const imageUrls = await Promise.all(uploadPromises);
            setUploadedImages(imageUrls);
            // setImageLinks(imageUrls); // Save the uploaded image links to imageLinks state
            setExpenseData({
                ...expenseData,
                imagesLinks: imageUrls,
            });
            toast.success('Image(s) uploaded successfully', {
                position: 'top-center',
                autoClose: 5000, // 5 seconds
            });
        } catch (error) {
            console.error('Error uploading images:', error);
        }
    };

    const handleImageLinksChange = (acceptedFiles) => {
        handleImageUpload(acceptedFiles);
    };

    
    const displayAllFields = expenseData.transactionType === 'reduced';

    const rechargeDefaultValues = expenseData.transactionType === 'added';


    const handleGoToRevenuTableClick = () => {
        navigate('/RevenuTable', { replace: true });
    };

    function formatCurrency(amount) {
        return amount.toLocaleString('en-US', {
            style: 'currency',
            currency: 'GNF',
            minimumFractionDigits: 0,
        });
    }


    return (
        <Container>
            <Paper elevation={3} style={{ padding: '16px', maxWidth: '400px', margin: '0 auto', marginTop: '36px' }}>
                <Typography variant="h5" style={{ marginBottom: '16px' }}>
                   Nouveau Revenu 
                </Typography>

                <FormControl
                disabled
                
                style={{ marginBottom: '16px', minWidth: '100%' }}>
                    <InputLabel 
                      style={{
                                backgroundColor: 'white',
                                padding: '0px 12px 0 12px'
                            }}
                    >Type de transaction</InputLabel>
                    <Select
                        name="transactionType"
                        value={expenseData.transactionType}
                        onChange={handleTransactionTypeChange}
                    >
                        <MenuItem value="reduced">Depense</MenuItem>
                        <MenuItem value="added">Recharge Compte Revenu </MenuItem>
                    </Select>
                </FormControl>
                

             
 

                <FormControl style={{ marginBottom: '16px', minWidth: '100%' }}>
                    <InputLabel
                        style={{
                            backgroundColor: 'white',
                            padding: '0px 12px 0 12px'
                        }}
                    >Budget Account</InputLabel>
                    <Select
                        label="Budget Name"
                        name="budgetName"
                        value={expenseData.budgetName}
                        onChange={handleBudgetNameChange}
                        fullWidth

                    >
                        <MenuItem value="Compte Revenu">Compte Revenu  </MenuItem>

                        {/* Add more options as needed */}
                    </Select>
                </FormControl>

             
                        <FormControl
                            style={ { marginBottom: '16px', minWidth: '100%' } }>
                            <InputLabel
                                style={ {
                                    backgroundColor: 'white',
                                    padding: '0px 12px 0 12px'
                                } }
                            >Type</InputLabel>
                            <Select
                                name="type"
                                value={ expenseData.type }
                                onChange={ handleTypeChange }
                            >
                                <MenuItem value="revenu">revenu</MenuItem>

                                {/* Add more options as needed */ }
                            </Select>
                        </FormControl>

                 


               
                <TextField
                    label="Item Name"
                    name="item.itemName"
                    value={expenseData.item.itemName}
                    onChange={handleItemNameChange}
                    fullWidth
                    style={{ marginBottom: '16px' }}
                />
                {displayAllFields && (

                    <>
                    
                      <TextField
                    label="Unit"
                    name="item.unit"
                    value={expenseData.item.unit}
                    onChange={handleUnitChange}
                    fullWidth
                    style={{ marginBottom: '16px' }}
                />
             
                    </>
                )}


                <TextField
                    label="Quantity"
                    type="number"
                    name="item.quantity"
                    value={ expenseData.item.quantity }
                    onChange={ handleQuantityChange }
                    fullWidth
                    style={ { marginBottom: '16px' } }
                />
              
                <TextField
                    label="Price"
                    type="number"
                    name="item.price"
                    value={expenseData.item.price}
                    onChange={handlePriceChange}
                    fullWidth
                    style={{ marginBottom: '16px' }}
                />

                <Typography
                    style={ { 
                        fontWeight: 'bold',
                        marginBottom: '16px' } }

                >
                 Total :   { formatCurrency(expenseData.item.price * expenseData.item.quantity)}
                </Typography>





                {displayAllFields && (
                //       <TextField
                //     label="Status"
                //     name="status"
                //     value={expenseData.status}
                //     onChange={handleStatusChange}
                //     fullWidth
                //     style={{ marginBottom: '16px' }}
                // />

                  <FormControl style={{ marginBottom: '16px', minWidth: '100%' }}>
                            <InputLabel>Status</InputLabel>
                            <Select
                               label="Status"
                    name="status"
                    value={expenseData.status}
                    onChange={handleStatusChange}
                    fullWidth
                            >
                                <MenuItem value="completed">Completed</MenuItem>
                                <MenuItem value="onGoing">On Going</MenuItem>
                            <MenuItem value="reported"> Reported </MenuItem>
                            <MenuItem value="cancelled"> Cancelled </MenuItem>
                                {/* Add more options as needed */}
                            </Select>
                        </FormControl>
                )}

                <FormControl style={{ marginBottom: '16px', minWidth: '100%' }}>
                    <InputLabel>Mode De Paiement</InputLabel>
                    <Select
                        label="Mode De Paiement"
                        name="modeDePaiement"
                        value={expenseData.modeDePaiement}
                        onChange={handleModePaiementNameChange}
                        fullWidth

                    >
                        <MenuItem value="Orange Money"> Orange Money </MenuItem>
                        <MenuItem value="Especes">  Especes </MenuItem>

                        {/* Add more options as needed */}
                    </Select>
                </FormControl>

                <TextField
                    label="frais De Retrait"
                    type="number"
                    name="fraisDeRetrait"
                    value={expenseData.fraisDeRetrait}
                    onChange={handleFraideRetraitChange}
                    fullWidth
                    style={{ marginBottom: '16px' }}
                />

                <TextField
                    label="frais De Depot"
                    type="number"
                    name="fraisDeDepot"
                    value={expenseData.fraisDeDepot}
                    onChange={handleFraideDepotChange}
                    fullWidth
                    style={{ marginBottom: '16px' }}
                />

                
              
           

                <Dropzone onDrop={handleImageLinksChange} accept="image/*" multiple>
                    {({ getRootProps, getInputProps }) => (
                        <div
                            {...getRootProps()}
                            style={{
                                border: '1px dashed #ccc',
                                padding: '20px',
                                textAlign: 'center',
                            }}
                        >
                            <input {...getInputProps()} />
                            <p>Click Here , for Photo Evidence</p>
                            {uploadedImages.length > 0 && (
                                <Stack direction="row" spacing={1}>
                                    {uploadedImages.map((imageUrl, index) => (
                                        <Image
                                            key={index}
                                            cloudName="ds1hlry5u"
                                            publicId={imageUrl}
                                            width="100"
                                        />
                                    ))}
                                </Stack>
                            )}
                        </div>
                    )}
                </Dropzone>

                

                <TextField
                    label="By Who"
                    name="toWhom"
                    value={expenseData.byWho}
                    onChange={handleByWhoChange}
                    fullWidth
                    style={{ marginBottom: '16px', marginTop: '16px' }}
                />

                <TextField
                    label="To Whom"
                    name="toWhom"
                    value={expenseData.toWhom}
                    onChange={handleToWhomChange}
                    fullWidth
                    style={{ marginBottom: '16px', marginTop: '16px' }}
                />


                <TextField
                    label="Comment"
                    name="comment"
                    value={expenseData.comment}
                    onChange={handleCommentChange}
                    fullWidth
                    style={{ marginBottom: '16px' }}
                />


                <TextField
                    label="Date"
                    type='date'
                    name="date"
                    value={ expenseData.date }
                    onChange={ handleDateChange }
                    fullWidth
                    style={ { marginBottom: '16px' } }
                    InputLabelProps={ { shrink: true } }

                />


             
                <Button
          
                >
                    
                </Button>


                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    style={{ marginTop: '16px', width: '100%' }}
                    // style={buttonStyle}
                    disabled={isSubmitting} // Disable button while submitting
                >
                    {isSubmitting ? (
                        <CircularProgress size={24} color="inherit" /> // Show CircularProgress while submitting
                    ) : (
                        'Submit Expense'
                    )}
                </Button>




                {/* ToastContainer for displaying the toast messages */}
                <ToastContainer position="top-center" />
                <Fab color="secondary" size="small"
                    style={{
                        position: 'fixed', top: '90px', left: 'calc(50% - 50px)',
                        zIndex: 999, transform: 'translateX(-50%)',
                        background: 'linear-gradient(90deg, lightcoral, blue)',
                        marginRight: '10px', borderRadius: '50%'
                    }}
                    onClick={() => handleGoToRevenuTableClick()}>
                    <ListIcon />
                </Fab>

            </Paper>
        </Container>
    );
};

export default ExpenseForm;
