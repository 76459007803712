import React, { useState } from 'react';
 
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, CircularProgress} from '@mui/material';

import { useTranslation } from 'react-i18next';



function PinCodeChangeForm() {
    const [adminPassword, setadminPassword] = useState('');
    const [newPassword, setnewPassword] = useState('');
    const [tel, settel] = useState('');
    const authToken = localStorage.getItem('token');
    const { t } = useTranslation();

    const [isSubmitting, setIsSubmitting] = useState(false);


    const navigate = useNavigate(); // useNavigate hook

    const handleadminPasswordChange = (event) => {
        setadminPassword(event.target.value);
    };

    const handlenewPassword = (event) => {
        setnewPassword(event.target.value);
    };

    const handletelChange = (event) => {
        settel(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsSubmitting(true);

        console.log(authToken);
 
            try {
                const response = await axios.put(
                    `${process.env.REACT_APP_BASE_URL}/user/resetUserPassword`,
                    
                    {
                        adminPassword,
                        newPassword,
                        tel
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${authToken}`,
                        },
                    }
                );

                if (response.ok) {
                    // console.log(`${t("Passwordchangesuccessful")}`, response.data);
                    toast.success(`password reset`, {
                        position: 'top-center',
                        autoClose: 5000, // 5 seconds
                    });



                    // // Delay the navigation by 3 seconds
                    // setTimeout(() => {
                    //     navigate('/'); // Use the navigate function to redirect

                    // }, 3000);



                } else {
                    // console.error('Password change failed', error);
                    toast.error(`error`, {
                        position: 'top-center',
                        autoClose: 5000, // 5 seconds
                    });
                    setIsSubmitting(false);


                }


                // console.log('Password reset successful', response.data);
                toast.success(`${t("Passwordresetsuccessful")}`, {
                    position: 'top-center',
                    autoClose: 5000, // 5 seconds
                });

                setadminPassword('')
                setnewPassword('')
                settel('')
         




            } catch (error) {
                // console.error('Password reset  failed', error);
                toast.error(`${t("PasswordresetfailedorUsernotexist")}`, {
                    position: 'top-center',
                    autoClose: 5000, // 5 seconds
                });

                setIsSubmitting(false);

            }
      
    };

    return (
        <form onSubmit={handleSubmit}>
            {/* Add margin to the TextField components */}
            <TextField
                label={`${t('UserTel')}`}

                type="text"
                value={tel}
                onChange={handletelChange}
                fullWidth
                required
                sx={{ margin: '8px 0' }}
            />
            <TextField
                label={`${t('UserNewPassword')}`}

                type="text"
                value={newPassword}
                onChange={handlenewPassword}
                fullWidth
                required
                sx={{ margin: '8px 0' }} 
            />
          
            <TextField
                label={`${t('AdminPassword')}`}

                type="password"
                value={adminPassword}
                onChange={handleadminPasswordChange}
                fullWidth
                required
                sx={{ margin: '8px 0' }}
            />
            {/* <Button type="submit" variant="contained" color="primary">
               {t("ResetUserPassword")}
            </Button> */}

            <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                // style={buttonStyle}
                style={{ marginTop: '20px', position: 'relative' }} // Position relative for CircularProgress
                disabled={isSubmitting} // Disable button while submitting
            >
                {isSubmitting ? (
                    <CircularProgress size={24} color="inherit" /> // Show CircularProgress while submitting
                ) : (
                    'Reset'
                )}
            </Button>
            <ToastContainer position="top-center" autoClose={5000} />


        </form>
    );
}

export default PinCodeChangeForm;
