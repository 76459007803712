import React, { useState } from 'react';
import { TextField, Button,CircularProgress } from '@mui/material';

import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';

import { useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';









function PasswordChangeForm() {
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const authToken = localStorage.getItem('accessToken');
    const { t } = useTranslation();

    const [isSubmitting, setIsSubmitting] = useState(false);


    const navigate = useNavigate(); // useNavigate hook

    const handleOldPasswordChange = (event) => {
        setOldPassword(event.target.value);
    };

    const handleNewPasswordChange = (event) => {
        setNewPassword(event.target.value);
    };

    const handleConfirmNewPasswordChange = (event) => {
        setConfirmNewPassword(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsSubmitting(true);


        console.log(authToken);

        if (newPassword === confirmNewPassword) {


            const authToken = localStorage.getItem('token');
            const authorizationHeader = `Bearer ${authToken}`;
            
            try {

                const response = await fetch(`${process.env.REACT_APP_BASE_URL}/user/updateOwn`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: authorizationHeader,
                    },
                    body: JSON.stringify({
                        oldPassword,
                        newPassword,
                    }),
                });

              
                if (response.ok) {
   // console.log(`${t("Passwordchangesuccessful")}`, response.data);
                    toast.success(`Password change successful`, {
                    position: 'top-center',
                    autoClose: 5000, // 5 seconds
                });

                // setOldPassword('')
                // setNewPassword('')
                localStorage.removeItem('user_name')
                localStorage.removeItem('token')
                localStorage.removeItem('user_role')
                localStorage.removeItem('userData')


                    // Delay the navigation by 3 seconds
                    setTimeout(() => {

                        navigate('/login'); // Use the navigate function to redirect


                    }, 3000);



                } else{
                    // console.error('Password change failed', error);
                    toast.error(`${t("Incorrect Password")}`, {
                        position: 'top-center',
                        autoClose: 5000, // 5 seconds
                    });
                    setIsSubmitting(false);


                }

             
            } catch (error) {
                // console.error('Password change failed', error);
                toast.error(`${error}`, {
                    position: 'top-center',
                    autoClose: 5000, // 5 seconds
                });
                setIsSubmitting(false);

            }
        } else {
            // console.error('Passwords do not match');
            toast.error(`Passwords do not match`, {
                position: 'top-center',
                autoClose: 5000, // 5 seconds
            });
            setIsSubmitting(false);

        }
    };

    return (
        <form onSubmit={handleSubmit}>
            {/* Add margin to the TextField components */}
            <TextField
                label={`${t('CurrentPassword')}`}

                type="password"
                value={oldPassword}
                onChange={handleOldPasswordChange}
                fullWidth
                required
                sx={{ margin: '8px 0' }} 
            />
            <TextField
                label={`${t('NewPassword')}`}

                type="password"
                value={newPassword}
                onChange={handleNewPasswordChange}
                fullWidth
                required
                sx={{ margin: '8px 0' }} 
            />
            <TextField
                label={`${t('ConfirmNewPassword')}`}

                type="password"
                value={confirmNewPassword}
                onChange={handleConfirmNewPasswordChange}
                fullWidth
                required
                sx={{ margin: '8px 0' }} 
            />
      
            <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                // style={buttonStyle}
                style={{ marginTop: '20px', position: 'relative' }} // Position relative for CircularProgress
                disabled={isSubmitting} // Disable button while submitting
            >
                {isSubmitting ? (
                    <CircularProgress size={24} color="inherit" /> // Show CircularProgress while submitting
                ) : (
                    'Login'
                )}
            </Button>

            <ToastContainer position="top-center" autoClose={5000} />

        </form>
    );
}

export default PasswordChangeForm;
