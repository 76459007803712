import React, { useState } from 'react';
import { TextField, Button,CircularProgress } from '@mui/material';

import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';

import { useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';









function NameChangeForm() {
    const [name, setName] = useState('');
    const { t } = useTranslation();

    const [isSubmitting, setIsSubmitting] = useState(false);


    const navigate = useNavigate(); // useNavigate hook

   

    const handleCameChange = (event) => {
        setName(event.target.value);
    };

   

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsSubmitting(true);

            const authToken = localStorage.getItem('token');
            const authorizationHeader = `Bearer ${authToken}`;
            
            try {

                const response = await fetch(`${process.env.REACT_APP_BASE_URL}/user/updateOwn`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: authorizationHeader,
                    },
                    body: JSON.stringify({
                             name,
                    }),
                });

              
                if (response.ok) {
                    toast.success(`name change successful, Please Login Again`, {
                    position: 'top-center',
                    autoClose: 5000, // 5 seconds
                });

                // setOldname('')
                // setName('')
                localStorage.removeItem('user_name')
                localStorage.removeItem('token')
                localStorage.removeItem('user_role')
                localStorage.removeItem('userData')


                    // Delay the navigation by 3 seconds
                    setTimeout(() => {

                        navigate('/login'); // Use the navigate function to redirect


                    }, 3000);



                } else{
                    // console.error('name change failed', error);
                    toast.error(`${t("Incorrect name")}`, {
                        position: 'top-center',
                        autoClose: 5000, // 5 seconds
                    });
                    setIsSubmitting(false);


                }

             
            } catch (error) {
                // console.error('name change failed', error);
                toast.error(`${error}`, {
                    position: 'top-center',
                    autoClose: 5000, // 5 seconds
                });
                setIsSubmitting(false);

            }
        
    };

    return (
        <form onSubmit={handleSubmit}>
            {/* Add margin to the TextField components */}
      
            <TextField
                label={`${t('name')}`}

                type="name"
                value={name}
                onChange={handleCameChange}
                fullWidth
                required
                sx={{ margin: '8px 0' }} 
            />
      
      
            <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                // style={buttonStyle}
                style={{ marginTop: '20px', position: 'relative' }} // Position relative for CircularProgress
                disabled={isSubmitting} // Disable button while submitting
            >
                {isSubmitting ? (
                    <CircularProgress size={24} color="inherit" /> // Show CircularProgress while submitting
                ) : (
                    'Change'
                )}
            </Button>

            <ToastContainer position="top-center" autoClose={5000} />

        </form>
    );
}

export default NameChangeForm;
